<template>
  <div id="flightDetail" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <!-- <div class="N-Page T-size-36">{{ mode }} Flight</div> -->
        <!-- Edit -->
        <div class="N-Page T-size-36">Flight Schedule</div>
        <div class="b-statusStep">
          <div :class="divFD"></div>
          <div class="dash"></div>
          <div :class="divEN"></div>
          <div class="dash"></div>
          <div :class="divPI"></div>
          <div class="dash"></div>
          <div :class="divCTE"></div>
        </div>
      </div>
    </div>

    <div id="ownerContent" class="wrap-Main">
      <div class="box-S4 flex-between-center noPadding">
        <div class="box-S2">
          <div class="B-carddetail">
            <div class="panal-title">Create By</div>
            <div v-if="itemsDataFlight.cb">{{ itemsDataFlight.cb }}</div>
            <div v-else>-</div>
            <div v-if="itemsDataFlight.cb">
              {{ itemsDataFlight.cd | yyyyMMdd }}
              {{ itemsDataFlight.cd | HHmm }}
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <div class="box-S2">
          <div class="B-carddetail">
            <div class="panal-title">Edit By</div>
            <div v-if="itemsDataFlight.mb">{{ itemsDataFlight.mb }}</div>
            <div v-else>-</div>
            <div v-if="itemsDataFlight.mb">
              {{ itemsDataFlight.md | yyyyMMdd }}
              {{ itemsDataFlight.md | HHmm }}
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <!-- <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="panal-title">Approve By</div>
            <div>Sarawut Khattiyavayu</div>
            <div>99/99/99 99:99:00</div>
          </div>
        </div> -->
      </div>
      <div class="line marginTop"></div>
    </div>
    <div id="flightDetail2" class="wrap-Main">
      <div class="box-S1-3">
        <div class="box-S4">
          <div class="N-Page T-size-24">Flight Detail</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Ref No." v-model="ref_id" disabled>
                </v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Flight No." :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " v-model="flightno">
                </v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <!-- <v-select
                  class="hideMessage"
                  :items="itemsListCompany"
                  item-text="c_name"
                  item-value="c_id"
                  label="Company"
                  v-model="company"
                  :readonly=" readonly_eng || readonly_pilot"
                  return-object
                ></v-select> -->
                <!-- Edit -->
                <v-select class="hideMessage" :items="itemsListCustomer" item-text="o_name" item-value="o_id"
                  label="Customer" v-model="customer" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
              <div class="box-S2">
                <v-select class="hideMessage" :items="itemsListFlightType" item-text="ft_name" item-value="ft_id"
                  label="Type Of Flight" v-model="flighttype" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
            </div>

            <div class="b-row" v-show="flighttype.ft_id == 1">
              <div class="box-S2">
                <v-select class="hideMessage" :items="itemsListTerminal" item-text="tm_name" item-value="tm_id"
                  label="Terminal" v-model="terminal" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
              <div class="box-S2">
                <v-select class="hideMessage" :items="itemsListCheckInVideo" item-text="v_name" item-value="v_id"
                  label="Brief Check in Video" v-model="videoCheckin" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S4">
                <v-select class="hideMessage" :items="itemsListFlightStatus" item-text="fs_name" item-value="fs_id"
                  label="Flight Status" v-model="flightstatus" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
            </div>

            <div class="b-row" v-show="flighttype.ft_id == 1">
              <div class="box-S4">
                <v-select class="hideMessage" :items="itemsListFHBasic" item-text="Name" item-value="ID"
                  label="FH Basic" v-model="FHBasic" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S4">
                <v-text-field label="UOA Note" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " v-model="UOANote">
                </v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="box-S4">
          <div class="N-Page T-size-24">Pilot Management</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4" :style="[
            piloterrormess === 'Pass' || piloterrormess === null
              ? {}
              : {
                width: '100%',
                border: '2px solid #FF0000',
                marginbottom: '10px',
              },
          ]">
                <p></p>
                <!-- <v-combobox :items="itemsPilot" label="Pilot" item-text="pl_name" item-value="pl_id" :readonly="readonly_eng ||
                  readonly_flight ||
                  readonly_shore ||
                  readonly_finance ||
                  readonly_checkin
                  " v-model="pilot" :messages="piloterrormesstime" @change="CalPilotRank(pilot)">
                </v-combobox> -->
                <v-select class="hideMessage" :items="itemsPilot" label="Pilot" v-model="pilot"
                  :messages="piloterrormesstime" @change="CalPilotRank(pilot)" item-text="pl_name" item-value="pl_id"
                  return-object :readonly="readonly_eng ||
            readonly_flight ||
            readonly_shore ||
            readonly_finance ||
            readonly_checkin
            "></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4" :style="[
            copiloterrormess === 'Pass' || piloterrormess === null
              ? {}
              : {
                width: '100%',
                border: '2px solid #FF0000',
                marginbottom: '10px',
              },
          ]">
                <!-- <v-combobox class="hideMessage" :items="itemsCoPilot" label="Co-Pilot" v-model="copilot"
                  item-text="pl_name" item-value="pl_id" :readonly="readonly_eng ||
                    readonly_flight ||
                    readonly_shore ||
                    readonly_finance ||
                    readonly_checkin
                    " :messages="copiloterrormess" @change="CalCoPilotRank(copilot)"></v-combobox> -->
                <v-select class="hideMessage" :items="itemsCoPilot" label="Co-Pilot" v-model="copilot"
                  :messages="copiloterrormess" @change="CalCoPilotRank(copilot)" item-text="pl_name" item-value="pl_id"
                  return-object :readonly="readonly_eng ||
            readonly_flight ||
            readonly_shore ||
            readonly_finance ||
            readonly_checkin
            "></v-select>
              </div>
            </div>

            <div class="b-row" v-show="flighttype.ft_id != 1">
              <div class="box-S2">
                <!-- <v-combobox :items="itemsPilot" label="Pilot 3" item-text="pl_name" item-value="pl_id" :readonly="readonly_eng ||
                  readonly_flight ||
                  readonly_shore ||
                  readonly_finance ||
                  readonly_checkin
                  " v-model="pilot3">
                </v-combobox> -->
                <v-select class="hideMessage" :items="itemsPilot" label="Pilot 3" v-model="pilot3" item-text="pl_name"
                  item-value="pl_id" return-object :readonly="readonly_eng ||
            readonly_flight ||
            readonly_shore ||
            readonly_finance ||
            readonly_checkin
            "></v-select>
              </div>
              <div class="box-S2">
                <!-- <v-combobox :items="itemsIsPilot" label="Types of Pilot" item-text="pt_name" item-value="pt_id" :readonly="readonly_eng ||
                  readonly_flight ||
                  readonly_shore ||
                  readonly_finance ||
                  readonly_checkin
                  " v-model="pilot3IsPilot">
                </v-combobox> -->
                <v-select class="hideMessage" :items="itemsIsPilot" label="Types of Pilot" v-model="pilot3IsPilot"
                  item-text="pt_name" item-value="pt_id" return-object :readonly="readonly_eng ||
            readonly_flight ||
            readonly_shore ||
            readonly_finance ||
            readonly_checkin
            "></v-select>
              </div>
            </div>

            <div class="b-row" v-show="flighttype.ft_id != 1">
              <div class="box-S2">
                <!-- <v-combobox :items="itemsPilot" label="Pilot 4" item-text="pl_name" item-value="pl_id" :readonly="readonly_eng ||
                  readonly_flight ||
                  readonly_shore ||
                  readonly_finance ||
                  readonly_checkin
                  " v-model="pilot4">
                </v-combobox> -->
                <v-select class="hideMessage" :items="itemsPilot" label="Pilot 4" v-model="pilot4" item-text="pl_name"
                  item-value="pl_id" return-object :readonly="readonly_eng ||
            readonly_flight ||
            readonly_shore ||
            readonly_finance ||
            readonly_checkin
            "></v-select>
              </div>
              <div class="box-S2">
                <!-- <v-combobox :items="itemsIsPilot" label="Types of Pilot" item-text="pt_name" item-value="pt_id" :readonly="readonly_eng ||
                  readonly_flight ||
                  readonly_shore ||
                  readonly_finance ||
                  readonly_checkin
                  " v-model="pilot4IsPilot">
                </v-combobox> -->
                <v-select class="hideMessage" :items="itemsIsPilot" label="Types of Pilot" v-model="pilot4IsPilot"
                  item-text="pt_name" item-value="pt_id" return-object :readonly="readonly_eng ||
            readonly_flight ||
            readonly_shore ||
            readonly_finance ||
            readonly_checkin
            "></v-select>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S4 noFlex">
                <div>
                  Pilot Total Weight :
                  <span>{{ pilotTotalWeightKg }} kg</span>
                </div>
                <!-- <div>
                  Total Score :
                  <span v-if="sumrank != 0">{{ sumrank }}
                    <span v-if="sumrank >= 4">
                      <v-icon style="color: green !important; font-size: 20px">mdi-checkbox-marked-circle</v-icon>
                    </span></span><span v-else>-</span>
                </div> -->
                <div>
                  Assign By :
                  <span v-if="itemsDataFlight.f_assing_pilot_by != null">{{
            itemsDataFlight.f_assing_pilot_by
          }}</span><span v-else>-</span>
                </div>
                <div>
                  Assign Date :
                  <span v-if="itemsDataFlight.f_assing_pilot_date != null">{{ itemsDataFlight.f_assing_pilot_date |
            yyyyMMdd
                    }}
                    {{ itemsDataFlight.f_assing_pilot_date | HHmm }}</span><span v-else>-</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="box-S4">
          <div class="N-Page T-size-24">Aircraft Management</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <!-- <v-combobox class="hideMessage" :items="itemsListAircraft" item-text="ac_name" item-value="ac_id"
                  label="Aircraft" v-model="aircraft" :readonly="readonly_flight ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance ||
                    readonly_checkin
                    " return-object></v-combobox> -->
                <v-select class="hideMessage" :items="itemsListAircraft" label="Aircraft" v-model="aircraft"
                  item-text="ac_name" item-value="ac_id" :readonly="readonly_flight ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance ||
            readonly_checkin
            " return-object></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-checkbox v-model="aircraftBackup" label="Backup" :readonly="readonly_flight ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            "></v-checkbox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4 noFlex">
                <div>
                  Assign By :
                  <span v-if="itemsDataFlight.f_assing_aircraft_by != null">{{
            itemsDataFlight.f_assing_aircraft_by
          }}</span><span v-else>-</span>
                </div>
                <div>
                  Assign date :
                  <!-- <span v-if="itemsDataFlight.f_assing_aircraft_date != null"
                    >{{ itemsDataFlight.f_assing_aircraft_date | yyyyMMdd }}
                    {{ itemsDataFlight.f_assing_aircraft_date | HHmm }}</span -->
                  <!-- <span v-if="itemsDataFlight.f_assing_aircraft_date != null"
                    >{{ itemsDataFlight.f_assing_aircraft_date }}</span
                  ><span v-else>-</span> -->

                  <span v-if="itemsDataFlight.f_assing_aircraft_date != null">{{ itemsDataFlight.f_assing_aircraft_date
            | yyyyMMdd
                    }}
                    {{ itemsDataFlight.f_assing_aircraft_date | HHmm }}</span>
                </div>
                <!-- <div>
                  Accept by :
                  <span v-if="itemsDataFlight.f_accept_aircraft_by != null">{{
                    itemsDataFlight.f_accept_aircraft_by
                  }}</span
                  ><span v-else>-</span>
                </div>
                <div>
                  Accept date :
                  <span v-if="itemsDataFlight.f_accept_aircraft_date != null"
                    >{{ itemsDataFlight.f_accept_aircraft_date | yyyyMMdd }}
                    {{ itemsDataFlight.f_accept_aircraft_date | HHmm }}</span
                  ><span v-else>-</span>
                </div> -->
              </div>
            </div>
            <div class="b-row marginTop">
              <v-btn :disabled="button_view_aircraft_detail" text class="theme-btn-even btn-aircraft center w100" :to="{
            name: 'ManageAircraft',
            params: { mode: 'View', idflight: idflight },
          }">
                <span class="I-flight"></span>
                <span class="T-size-18">View Aircraft Details</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="destiantionDetail" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Departure</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-select :items="itemsListAirport" item-text="ap_name" item-value="ap_id" label="Departure Airport"
                  v-model="departureairport" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-menu :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " v-model="menuselect_depart_date" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field class="hideMessage input-date" v-model="depart_date" label="Departure Date" readonly
                      v-on="on" append-icon="mdi-calendar-range"></v-text-field>
                  </template>
                  <v-date-picker v-model="depart_date" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " @input="menuselect_depart_date = false"></v-date-picker>
                </v-menu>
              </div>

              <div class="box-S2">
                <v-text-field label="Departure Time" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " v-model="depart_time" type="time"></v-text-field>
              </div>
            </div>
            <!-- <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Visibility (KM)" :readonly=" readonly_eng || readonly_pilot" v-model="depart_visibility"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="WindSpeed (Knot)" :readonly=" readonly_eng || readonly_pilot" v-model="depart_wind_spd"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="OAT (°C)" v-model="depart_oat" :readonly=" readonly_eng || readonly_pilot"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="QNH" v-model="depart_qnh" :readonly=" readonly_eng || readonly_pilot"></v-text-field>
              </div>
            </div> -->
          </div>
        </div>

        <!-- Revise Departure -->
        <div class="box-S4 wrap-Main B-passenger" v-if="totalDepartureDate > 0">
          <div class="box-S4 flex-between-center">
            <div>
              <div style="margin-bottom: 10px; color: #fb0000 !important">
                <v-icon>mdi-clock</v-icon>
                Warning Different Form Booking Now
              </div>
            </div>
            <v-btn v-if="userdata.r_id == 4 || userdata.r_id == 5 || userdata.r_id == 21
            " class="right" flat color="primary" @click="syncDeparture()" style="margin-top: -10px">
              <v-icon>mdi-sync</v-icon>SYNC DEPARTUREDATE
            </v-btn>
          </div>
          <div class="box-S4 noPadding">
            <v-data-table :headers="headersReviseDeparture" :items="dataCompareDepartureDate" hide-actions class="test">
              <template slot="headerCell" slot-scope="{ header }">
                <span class="subheading font-weight-light text--darken-3" v-text="header.text" />
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Arrival</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-select :items="itemsListAirport" item-text="ap_name" item-value="ap_id" label="Arrival Airport"
                  v-model="destinationairport" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " return-object></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-menu :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " v-model="menuselect_dest_date" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field class="hideMessage input-date" v-model="dest_date" label="Arrival Date" readonly
                      v-on="on" append-icon="mdi-calendar-range"></v-text-field>
                  </template>
                  <v-date-picker v-model="dest_date" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " @input="menuselect_dest_date = false"></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-text-field label="Arrival Time" v-model="dest_time" :readonly="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " type="time"></v-text-field>
              </div>
            </div>
            <!-- <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Visibility (KM)" v-model="dest_visibility" :readonly=" readonly_eng || readonly_pilot"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="WindSpeed (Knot)" v-model="dest_wind_spd" :readonly=" readonly_eng || readonly_pilot"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="OAT (°C)" v-model="dest_oat" :readonly=" readonly_eng || readonly_pilot"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="QNH" v-model="dest_qnh" :readonly=" readonly_eng || readonly_pilot"></v-text-field>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="manageLeg" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Manage Leg</div>
      </div>
      <div class="box-S4 noPadding">
        <div class="wrap-leg">
          <div class="box-S1-3" v-for="(item, index) in listAddLegs" :key="item.Idflightlegs">
            <div class="B-passenger leg">
              <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
              <div>
                <div class="b-person">
                  <div class="b-name-company">
                    <p class="t-name">{{ item.legs.ap_name }}</p>
                    <div class="t-company T-size-12" v-show="item.activity5 == true">
                      S/D
                    </div>
                    <div class="t-company T-size-12" v-show="item.activity1 == true">
                      S/D Refuel
                    </div>
                    <div class="t-company T-size-12" v-show="item.activity2 == true">
                      S/D Refuel and HLO familiarize
                    </div>
                    <div class="t-company T-size-12" v-show="item.activity3 == true">
                      S/D FOR JET A1 INSPECTION
                    </div>
                    <div class="t-company T-size-12" v-show="item.activity4 == true">
                      <!-- EDRILL-1 -->
                      S/D for Helideck Inspec
                    </div>
                  </div>
                  <div class="b-action">
                    <div class="b-btn">
                      <v-btn fab class="btn-edit" :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " @click="EditItem(item, index)">
                        <span class="I-edit"></span>
                      </v-btn>
                      <v-btn fab class="primary btn-delete" :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " @click="deleteItem(item)">
                        <span class="I-bin"></span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="b-detail">
                  <div class="t-title">Arrival Time</div>
                  <div class="box-S4 flex-between-center t-detail">
                    <div class="bL">
                      <span class="T-size-12">Estimate Time</span>
                      <p class="T-size-16">{{ item.l_eta }}</p>
                    </div>
                  </div>
                </div>
                <div class="b-detail">
                  <div class="t-title">Departure Time</div>
                  <div class="box-S4 flex-between-center t-detail">
                    <div class="bL">
                      <span class="T-size-12">Estimate Time</span>
                      <p class="T-size-16">{{ item.l_etd }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="b-recover">
                <v-btn class="theme-btn-even btn-recover">
                  <span class="T-size-18">Recover</span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Revise Leg -->
      <div class="box-S4 wrap-Main B-passenger" v-if="totalLeg > 0">
        <div class="box-S4 flex-between-center">
          <div>
            <div style="margin-bottom: 10px; color: #fb0000 !important">
              <v-icon>mdi mdi-airport</v-icon>
              Warning Different {{ total }} Leg (Booking Now)
            </div>
          </div>
          <v-btn v-if="userdata.r_id == 4 || userdata.r_id == 5 || userdata.r_id == 21
            " class="right" flat color="primary" @click="syncDataLeg()" style="margin-top: -10px">
            <v-icon>mdi-sync</v-icon>Sync Leg
          </v-btn>
        </div>
        <div class="box-S4 noPadding">
          <v-data-table :headers="headersReviseLeg" :items="dataCompareLeg" hide-actions class="test">
            <template slot="headerCell" slot-scope="{ header }">
              <span class="subheading font-weight-light text--darken-3" v-text="header.text" />
            </template>
          </v-data-table>
        </div>
      </div>

      <div class="box-S1-3">
        <v-btn class="theme-btn-even MGB10" v-show="!(
            readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
          )
            " @click="addItem()">
          <span class="I-create"></span>
          <span>Add</span>
        </v-btn>
      </div>
      <div class="line"></div>
    </div>

    <CompAddPassengerOutbound2 ref="CompAddPassengerOutbound2" @SumDataBodyWeightOutbound="BodyOut"
      @SumDataBaggageWeightOutbound="BaggageOut" :customer="customerPassenger.o_name" :flightId="$route.params.id">
    </CompAddPassengerOutbound2>
    <CompAddPassengerInbound2 ref="CompAddPassengerInbound2" @SumDataBodyWeightInbound="BodyIn"
      @SumDataBaggageWeightInbound="BaggageIn" :customer="customerPassenger.o_name" :flightId="$route.params.id">
    </CompAddPassengerInbound2>

    <div id="Cargo" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Cargo</div>
        <v-btn class="theme-btn-even" v-show="!(
            readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
          )
            " @click="addItemCargo()">
          <span class="I-create"></span>
          <span>Add cargo</span>
        </v-btn>
      </div>
      <div class="box-S4">
        <div class="wrap-cargo">
          <div class="box-S1-3" v-for="(item, index) in listAddCargo" :key="item.Idflightcargo">
            <div class="B-passenger cargo">
              <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
              <div>
                <div class="b-person">
                  <div class="b-name-company">
                    <p class="t-name T-size-14">Item Name</p>
                  </div>
                  <div class="b-action">
                    <div class="b-btn">
                      <v-btn fab class="btn-edit" :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " @click="EditItemCargo(item, index)">
                        <span class="I-edit"></span>
                      </v-btn>
                      <v-btn fab class="primary btn-delete" :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance
            " @click="deleteItemCargo(item)">
                        <span class="I-bin"></span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="b-detail">
                  <div class="box-S4 flex-between-center t-detail">
                    <div class="bL">
                      <span class="T-size-12">DAN No.</span>
                      <p class="T-size-16">{{ item.cg_dan_no }}</p>
                    </div>
                    <div class="bL">
                      <span class="T-size-12">Weight</span>
                      <p class="T-size-16">{{ item.cg_weight }}</p>
                    </div>
                    <div class="bR">
                      <span class="T-size-12">Destination</span>
                      <p class="T-size-16">{{ item.Destinations.ap_name }}</p>
                    </div>
                  </div>
                </div>
                <div class="b-detail">
                  <div class="box-S4 flex-between-center t-detail">
                    <div class="bL">
                      <span class="T-size-12">Consigned to</span>
                      <p class="T-size-16">{{ item.cg_contact }}</p>
                    </div>
                  </div>
                </div>
                <div class="b-detail">
                  <div class="t-title">Cargo Details</div>
                  <div class="box-S4 t-detail">
                    <p class="T-size-16 detail">{{ item.cg_remark }}</p>
                  </div>
                </div>
              </div>
              <div class="b-recover">
                <v-btn class="theme-btn-even btn-recover">
                  <span class="T-size-18">Recover</span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="payload" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Payload</div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-bodyweight"></span>
            </div>
            <div class="b-text">
              <p>Body Weight</p>
              <p class="T-size-20 SemiBold">
                {{ sumbodyweight.toFixed(2) }} Kg.
              </p>
              <!-- <p class="T-size-20 SemiBold"  v-if="mode == 'Create'">0.00 Kg.</p>
              <p class="T-size-20 SemiBold" v-else>{{itemsCalWeightFlight.body_weight[0].toFixed(2)}} Kg.</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-baggageweight"></span>
            </div>
            <div class="b-text">
              <p>Baggage Weight</p>
              <p class="T-size-20 SemiBold">
                {{ sumbaggageweight.toFixed(2) }} Kg.
              </p>
              <!-- <p class="T-size-20 SemiBold"  v-if="mode == 'Create'">0.00 Kg.</p>
              <p class="T-size-20 SemiBold" v-else>{{itemsCalWeightFlight.baggage_weight[0].toFixed(2)}} Kg.</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-cargo"></span>
            </div>
            <div class="b-text">
              <p>Cargo Weight</p>
              <!-- <p class="T-size-20 SemiBold"  v-if="mode == 'Create'">0.00 Kg.</p>
               <p class="T-size-20 SemiBold" v-else>{{itemsCalWeightFlight.cargo_weight[0].toFixed(2)}} Kg.</p> -->

              <p class="T-size-20 SemiBold">{{ sumcargo.toFixed(2) }} Kg.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-totalweight"></span>
            </div>
            <div class="b-text">
              <p>Total Weight</p>
              <p class="T-size-20 SemiBold">{{ sumTotal.toFixed(2) }} Kg.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <div id="payloadEngine" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Available Payload (for checkin)</div>
        </div>
        <!-- Button Input Available Payload -->
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <div class="B-carddetail">
                  <v-btn class="theme-btn-even btn-seat2 center Bsize190" @click="dialogInputAvailablePayload = true"
                    v-if="available_payload != null && available_payload != ''">
                    <!-- <v-icon>mdi-weight-lifter</v-icon> -->
                    <span class="T-size-18">{{ available_payload }} Kg.</span>
                  </v-btn>
                  <v-btn v-if="available_payload == null || available_payload == ''"
                    class="theme-btn-even btn-seat center Bsize190" @click="dialogInputAvailablePayload = true">
                    <!-- <v-icon>mdi-weight-lifter</v-icon> -->
                    <span class="T-size-18">- kg</span>
                  </v-btn>
                </div>

                <div class="B-carddetail">
                  <v-btn class="theme-btn-even btn-seat2 center Bsize190" @click="dialogInputAvailablePayloadLbs = true"
                    v-if="available_payload_lbs != null && available_payload_lbs != ''">
                    <!-- <v-icon>mdi-weight-lifter</v-icon> -->
                    <span class="T-size-18">{{ available_payload_lbs }} Lbs.</span>
                  </v-btn>
                  <v-btn v-if="available_payload_lbs == null || available_payload_lbs == ''"
                    class="theme-btn-even btn-seat center Bsize190" @click="dialogInputAvailablePayloadLbs = true">
                    <!-- <v-icon>mdi-weight-lifter</v-icon> -->
                    <span class="T-size-18">- Lbs</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Button Input Available Payload -->

      <!-- Dialog Input Available Payload -->
      <v-dialog v-model="dialogInputAvailablePayload" persistent max-width="400">
        <v-card id="dialog" class="TF type2">
          <div class="b-content">
            <div class="B-dialogform">
              <div class="t-namepage">Available Payload (Kg)</div>
              <div class="b-form">
                <v-text-field label="Available Payload (Kg)" type="number" v-model="available_payload"
                  :readonly="!(userdata.r_id == 4 || userdata.r_id == 21)"></v-text-field>
              </div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="CancelSaveAvailablePayload()">
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn class="theme-btn-even btn-save Bsize100" text
                :disabled="!(userdata.r_id == 4 || userdata.r_id == 21)" @click="SaveAvailablePayload()">
                <span class="T-size-18">Save</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <!-- Dialog Input Available Payload -->

      <!-- Dialog Input Available Payload -->
      <v-dialog v-model="dialogInputAvailablePayloadLbs" persistent max-width="400">
        <v-card id="dialog" class="TF type2">
          <div class="b-content">
            <div class="B-dialogform">
              <div class="t-namepage">Available Payload (Lbs)</div>
              <div class="b-form">
                <v-text-field label="Available Payload (Lbs)" type="number" v-model="available_payload_lbs"
                  :readonly="!(userdata.r_id == 4 || userdata.r_id == 21)"></v-text-field>
              </div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="CancelSaveAvailablePayloadLbs()">
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn class="theme-btn-even btn-save Bsize100" text
                :disabled="!(userdata.r_id == 4 || userdata.r_id == 21)" @click="SaveAvailablePayloadLbs()">
                <span class="T-size-18">Save</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <!-- Dialog Input Available Payload -->
       <!-- ONLY VTBU -->


      <!-- ปิด Actual Engine Start -->
      <!-- <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Actual Engine Start</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row flex-between-center">
              <div class="box-S2">
                <v-menu
                  :disabled="
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                  "
                  v-model="menuselect_actual_engine_start_date"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  :nudge-top="10"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="input-date"
                      append-icon="mdi-calendar-range"
                      v-model="actual_engine_start_date"
                      label="Date"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :readonly="
                      readonly_eng ||
                      readonly_pilot ||
                      readonly_shore ||
                      readonly_finance
                    "
                    v-model="actual_engine_start_date"
                    @input="menuselect_actual_engine_start_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-text-field
                  class="hideMessage"
                  label="Time"
                  type="time"
                  :readonly="
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                  "
                  v-model="actual_engine_start_time"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="line"></div>
    </div>
    <div id="createFlightReport" class="wrap-Main">
      <!--<div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Create Flight</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn class="theme-btn-even btn-flight center Bsize190"  @click="DelayReport()">
              <span class="I-flight"></span>
              <span class="T-size-18">Create Flight</span>
            </v-btn>
          </div>
        </div> 
      </div>-->
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Report</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn class="theme-btn-even btn-report center Bsize190" @click="SemiManifest()">
              <span class="I-report"></span>
              <!-- <span class="T-size-18">Semi-Manifest</span> -->
              <span class="T-size-18">Booking Report</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="box-S2" v-if="userdata.r_id == 4 || userdata.r_id == 21">
        <div class="box-S4">
          <div class="N-Page T-size-24">Delete Flight</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn class="theme-btn-even btn-delete center Bsize190" @click="dialogDelete = true">
              <span class="I-bin"></span>
              <!-- <span class="T-size-18">Semi-Manifest</span> -->
              <span class="T-size-18">Delete Flight</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <CompAddReviseFlight v-show="userdata.r_id == 4 || userdata.r_id == 21" ref="CompAddReviseFlight"
      @ReviseRenderFlight="ReviseRenderFlight()" :customer="customerPassenger.o_name" :flightId="$route.params.id"
      :master="master"></CompAddReviseFlight>

    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSave">
          <v-btn class="theme-btn-even btn-cancle Bsize150" @click="GotoListFlight()">
            <span class="T-size-18">Cancel</span>
          </v-btn>
          <v-btn class="theme-btn-even btn-save Bsize150" v-show="userdata.r_id !== 9 &&
            userdata.r_id !== 12 &&
            userdata.r_id !== 21
            " @click="SaveEditToDB()">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
          <v-btn class="theme-btn-even btn-save Bsize150" v-show="userdata.r_id == 9" @click="SaveEditToDB_Role_En()">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
          <v-btn class="theme-btn-even btn-save Bsize150" v-show="userdata.r_id == 12" @click="SaveEditToDB_Role_Pi()">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
          <!-- Only Super Flight off -->
          <v-btn class="theme-btn-even btn-save Bsize150" v-show="userdata.r_id == 21"
            @click="SaveEditToDB_Role_Super_Flight_Off()">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
        </div>
        <div class="b-btnDelete" v-show="mode == 'edit'">
          <v-btn class="theme-btn-even btn-delete Bsize150">
            <span class="T-size-18">delete booking</span>
          </v-btn>
        </div>
      </div>

      <div class="box-S4" v-if="userdata.r_id == 12 || userdata.r_id == 21">
        <div class="b-btnSave">

          <v-btn class="theme-btn-even btn-report center" @click="AcceptPilot()" text>
            <span class="T-size-18">Accept Pilot And Send Notification</span>
          </v-btn>
        </div>
      </div>
      <div class="box-S4" v-if="userdata.r_id == 9 || userdata.r_id == 21">
        <div class="b-btnSave">
          <v-btn class="theme-btn-even btn-report center" @click="AcceptAircraft()" text>
            <span class="T-size-18">Accept Aircraft And Send Notification</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="dialogDelete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog delete">
            <div class="t-title">Confirm Deletion</div>
            <div class="t-des">
              Are you sure to permanently delete this item?
              <div>Please type "YES" to confirm.</div>
              <div class="B-carddetail B-formMain">
                <div class="b-row">
                  <div class="box-S4">
                    <v-text-field class="hideMessage" label="Confirm" type="text" v-model="confirmdeleteflight">
                    </v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-S4"></div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogDelete = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-delete Bsize100" text @click="deleteflight1()">
              <span class="T-size-18">Delete</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEvent" persistent max-width="400">
      <v-card id="dialog" class="TF type3">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Add Leg</div>
            <div class="b-form">
              <!-- <v-combobox class="hideMessage" :items="itemsListAirport" item-text="ap_name" item-value="ap_id"
                label="Airport" v-model="legs" return-object></v-combobox> -->
              <v-select class="hideMessage" :items="itemsListAirport" label="Airport" v-model="legs" item-text="ap_name"
                item-value="ap_id" return-object></v-select>
              <div class="b-checkbox">
                <v-checkbox v-model="activity5" label="S/D"></v-checkbox>
                <v-checkbox v-model="activity1" label="S/D Refuel"></v-checkbox>
                <v-checkbox v-model="activity2" label="S/D Refuel and HLO familiarize"></v-checkbox>
                <v-checkbox v-model="activity3" label="S/D FOR JET A1 INSPECTION"></v-checkbox>
                <!-- <v-checkbox v-model="activity4" label="EDRILL-1"></v-checkbox> -->
                <v-checkbox v-model="activity4" label="S/D for Helideck Inspec"></v-checkbox>
              </div>
            </div>
            <div class="t-namepage2">Time</div>
            <div class="b-form">
              <div class="b-detail">
                <div class="box-S4 flex-between-center noPadding">
                  <div class="box-S2 noPadding t-detail">
                    <v-text-field label="Est. Arrival Time" v-model="l_eta" type="time"></v-text-field>
                  </div>
                  <div class="box-S2 noPadding t-detail">
                    <v-text-field label="Est. Departure Time" v-model="l_etd" type="time"></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogEvent = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveLegs()">
              <span class="T-size-18">save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Confirm Save Only Flight -->
    <v-dialog v-model="dialogSave" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Save ?</div>
            <div class="t-des">Are you sure to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSave = false" :disabled="buttonCancel">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" v-if="mode == 'Create'" text @click="CreateFlight()"
              :disabled="buttonSave">
              <span class="T-size-18">Save</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" v-else text @click="SaveEditToDB()" :disabled="buttonSave">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Confirm Save Only Super Flight off -->
    <v-dialog v-model="dialogSaveEP" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Save ?</div>
            <div class="t-des">Are you sure to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSaveEP = false">
              <span class="T-size-18">cancel</span>
            </v-btn>

            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveEditToDB_Role_Super_Flight_Off()">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Confirm Save Only Engineer -->
    <v-dialog v-model="dialogSaveE" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Save ?</div>
            <div class="t-des">Are you sure to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSaveE = false">
              <span class="T-size-18">cancel</span>
            </v-btn>

            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveEditToDB_Role_En()">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Confirm Save Only Pilot -->
    <v-dialog v-model="dialogSaveP" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Save ?</div>
            <div class="t-des">Are you sure to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSaveP = false">
              <span class="T-size-18">cancel</span>
            </v-btn>

            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveEditToDB_Role_Pi()">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Completed</div>
            <!-- <div class="t-des">
              Save Data Complete
              <br />You want to Create Transport?
              <v-btn
                class="theme-btn-even btn-transport center Bsize190"
                @click="GotoCreateTransport()"
              >
                <span class="I-transport"></span>
                <span class="T-size-18">Create Transport</span>
              </v-btn>
            </div> -->
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="SaveCompleted()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogWarning" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog warning3">
            <div class="t-title">Warning</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="dialogWarning = false">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCargo" persistent max-width="400">
      <v-card id="dialog" class="TF type3">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Add Cargo</div>
            <div class="b-form">
              <v-text-field class="hideMessage" v-model="cg_dan_no" label="DAN No."></v-text-field>
              <v-text-field class="hideMessage" v-model="cg_weight" label="Weight" type="number" suffix="Kg.">
              </v-text-field>
              <v-select class="hideMessage" :items="itemsListAirport" item-text="ap_name" item-value="ap_id"
                label="Destination" v-model="Destinations" return-object></v-select>

              <v-text-field class="hideMessage" v-model="cg_contact" label="Consigned to"></v-text-field>
            </div>
            <div class="t-namepage2">Cargo Details</div>
            <div class="b-form">
              <v-text-field class="hideMessage" v-model="cg_remark" label="Details"></v-text-field>
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogCargo = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveCargo()">
              <span class="T-size-18">save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogSendNotificationComplete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Send Notification Completed</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="SendNotificationCompleted()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fillerdate from "../utils/filterdate";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import CompAddPassengerOutbound2 from "../components/flight/CompAddPassengerOutboundFlight2";
import CompAddPassengerInbound2 from "../components/flight/CompAddPassengerInboundFlight2";
import CompAddReviseFlight from "../components/flight/CompAddReviseFlight";

import { format, addHours } from "date-fns";
import dayjs from "dayjs";
import { saveComplete } from "@syncfusion/ej2-vue-grids";
export default {
  data: () => ({
    dialogInputAvailablePayload: false,
    available_payload: "",
    dialogInputAvailablePayloadLbs: false,
    available_payload_lbs: "",
    master: null,

    dataCompareLeg: [],
    totalLeg: 0,
    headersReviseLeg: [
      { value: "Status", text: "Status", sortable: true },
      { value: "Name", text: "Name", sortable: true },
      // { value: "Depart", text: "Departure", sortable: true },
      // { value: "Dest", text: "Destination", sortable: true },
    ],

    dataCompareDepartureDate: [],
    totalDepartureDate: 0,
    headersReviseDeparture: [
      { value: " ", text: "", sortable: true },
      { value: "Departure", text: "Departure", sortable: true },
      { value: "Datetime", text: "Datetime Booking Now", sortable: true },
    ],

    //Add
    idflightlegs: null,
    buttonCancel: false,
    buttonSave: false,
    buttonAcceptPilot: false,
    buttonAcceptAircraft: false,

    // itemsListCompany: [],
    // company: "",
    //Edit
    itemsListCustomer: [],
    customer: "",
    piloterrormess: "",
    piloterrormesstime: "",
    copiloterrormess: "",
    copiloterrormesstime: "",

    readonly_super_flight: false,
    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_finance: false,
    readonly_checkin: false,

    menuselect_depart_date: false,
    menuselect_depart_time: false,
    menuselect_dest_date: false,
    menuselect_dest_time: false,
    //Add
    menuselect_actual_engine_start_date: false,

    menuselect_leg_eta: false,
    menuselect_leg_etd: false,
    dialogEvent: false,
    dialogSave: false,
    dialogSaveE: false,
    dialogSaveP: false,
    dialogSaveEP: false,
    dialogConfirm: false,
    loading: false,
    dialogDelete: false,
    dialogCargo: false,
    activity1: false,
    activity2: false,
    activity3: false,
    activity4: false,
    activity5: false,

    modeEvent: "",
    depart_date: "",
    depart_time: "",
    dest_date: "",
    dest_time: "",
    mode: "",
    terminal: "",
    videoCheckin: "",
    pilot: null,
    copilot: null,

    flighttype: "",
    flightstatus: "",
    aircraft: "",
    flightno: "",
    destinationairport: "",
    departureairport: "",
    depart_visibility: "",
    depart_oat: "",
    depart_wind_spd: "",
    depart_qnh: "",
    dest_visibility: "",
    dest_oat: "",
    dest_wind_spd: "",
    dest_qnh: "",
    l_weather_visibility: "",
    l_weather_oat: "",
    l_weather_wind_spd: "",
    l_weather_qnh: "",
    l_etd: "",
    l_eta: "",
    idflight: "",
    legs: "",
    cg_dan_no: "",
    cg_weight: "",
    cg_contact: "",
    cg_remark: "",
    Destinations: "",
    ref_id: "",
    actual_engine_start_date: "",
    actual_engine_start_time: "",

    sumbodyweight: 0,
    sumbaggageweight: 0,
    sumcargo: 0,
    sumTotal: 0,
    itemsPilot: [],
    itemsCoPilot: [],
    itemsDataFlight: [],
    itemsListAircraft: [],
    itemsListFlightType: [],
    itemsListTerminal: [],
    itemsListCheckInVideo: [],
    itemsListFlightStatus: [],
    itemsListFHBasic: [],
    itemsListPilot: [],
    itemsListCoPilot: [],
    itemsListLeg: [],
    itemsDataBooking: [],
    chooseItemLegs: null,
    chooseItemLegIndex: null,
    chooseItemCargo: null,
    chooseItemCargoIndex: null,
    itemsDataLegs: [],
    itemsDataCargo: [],
    listAddLegs: [],
    listAddCargo: [],
    ArrListPassengerIn: [],
    ArrListPassengerInterField: [],
    ArrListPassengerOut: [],
    listPassengersIn: [],
    listPassengersOut: [],
    listPassengersInterField: [],
    listPassengersInterFieldContent: [],
    // itemsListFlightType: [
    //   "Passenger revenue",
    //   "Aerial work revenue",
    //   "Maintenance",
    //   "Training",
    //   "Reposition",
    //   "VIP",
    //   "Others",
    // ],
    //Edit
    itemsListFlightType: [],
    itemsCalWeightFlight: [],
    sumfromOutboundPassenger: 0,
    sumfromInboundPassenger: 0,
    sumfromOutboundPassengerBaggage: 0,
    sumfromInboundPassengerBaggage: 0,

    userdata: "",
    divFD: "status FD",
    divEN: "status EN",
    divPI: "status PI",
    divCTE: "status CTE",

    // Report Passenger booking
    totalBody_KG_Out: 0,
    totalBaggage_KG_Out: 0,

    totalBody_LBS_Out: 0,
    totalBaggage_LBS_Out: 0,

    totalBody_KG_In: 0,
    totalBaggage_KG_In: 0,

    totalBody_LBS_In: 0,
    totalBaggage_LBS_In: 0,
    totalBody_KG_INTERFIELD: 0,
    totalBaggage_KG_INTERFIELD: 0,
    sumtotal_KG_INTERFIELD: 0,
    sumtotal_KG_Out: 0,
    sumtotal_LBS_Out: 0,
    sumtotal_KG_In: 0,
    sumtotal_LBS_In: 0,
    dataLegs: "",
    aircarftName: [],

    //Add
    button_view_aircraft_detail: true,
    aircraftBackup: false,

    checkbox: true,
    sumrank: 0,
    pilotrank: 0,
    copilotrank: 0,

    customerPassenger: {},
    itemsListAirport: null,

    sumPassengerOut: 0,
    sumPassengerIn: 0,

    dialogSendNotificationComplete: false,
    confirmdeleteflight: "",
    disabledelete: true,

    dialogWarning: false,

    //Add 20220323
    FHBasic: {},
    // ----------------------------------------------

    //Add 20230310
    UOANote: "",
    itemsIsPilot: [{ "pt_id": true, "pt_name": "Pilot" }, { "pt_id": false, "pt_name": "Co-Pilot" }],
    pilot3: null,
    pilot3IsPilot: null,
    pilot4: null,
    pilot4IsPilot: null,
    // ----------------------------------------------
    pilotTotalWeightKg: 0,
    pilotWeigt: 0,
    copilotWeight: 0,

  }),
  props: [],
  components: {
    CompAddPassengerOutbound2,
    CompAddPassengerInbound2,
    CompAddReviseFlight,
  },
  async mounted() {
    //this.userdata = JSON.parse(localStorage.getItem("user"));
    //Edit
    this.piloterrormess = null;
    this.copiloterrormess = null;
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    this.customerPassenger.o_name = await this.userdata.o_name;
    await this.enable_disable();

    this.mode = await this.$route.params.mode;
    this.b_id = await this.$route.params.id;

    //GetMasterData
    try {
      //Add await
      // await this.SelectListCustomer();
      // await this.SelectListFlightType();
      // await this.SelectListTerminal();
      // await this.SelectListCheckInVideo();
      // await this.SelectListFlightStatus();
      // await this.SelectListPilot();
      // await this.SelectListAircraft();
      // await this.SelectListAirport();
      //ยุบ SelectList
      await this.SelectAllList();
      await this.RenderUI();
      await this.CompareLeg();
    } catch (error) {
      console.log("mounted ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
    }
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async SelectAllList() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        this.master = data[0];

        //Customer
        this.itemsListCustomer = this.master.customer;
        this.customer = this.itemsListCustomer[0];
        //FlightType
        this.itemsListFlightType = this.master.flightType;
        this.flighttype = this.itemsListFlightType[0];
        //Terminal
        this.itemsListTerminal = this.master.terminal;
        this.terminal = this.itemsListTerminal[0];
        //CheckInVideo
        this.itemsListCheckInVideo = this.master.briefCheckInVideo;
        this.videoCheckin = this.itemsListCheckInVideo[0];
        //Flight Status
        this.itemsListFlightStatus = this.master.flightStatus;
        this.flightstatus = this.itemsListFlightStatus[0];
        //PILOT & CO PILOT
        this.master.pilot.forEach(element => {
          let newObj = {};
          newObj.pilot_rank = element.pilot_rank;
          newObj.pl_id = element.pl_id;
          newObj.pl_name = element.pl_name + "  [" + element.pl_weight_kg + " kg]";
          newObj.pl_weight_kg = element.pl_weight_kg;
          this.itemsPilot.push(newObj);
          this.itemsCoPilot.push(newObj);
        });
        //Aircraft
        this.itemsListAircraft = this.master.aircraft;
        //Airport & Leg
        this.itemsListAirport = this.master.airport;
        this.itemsListLeg = this.master.airport;
        //Default create flight no booking
        this.departureairport = this.itemsListAirport[0];
        this.destinationairport = this.itemsListAirport[0];

        //Add 20220323
        //FH Basic
        this.itemsListFHBasic = [
          { ID: "Airtime", Name: "Airtime" },
          { ID: "Blocktime", Name: "Blocktime" },
        ];
      } catch (error) {
        console.log(
          "SelectAllList ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },

    async RenderUI() {
      //Add
      this.buttonCancel = false;
      this.buttonSave = false;

      if (this.mode == "Edit") {
        this.idflight = await this.$route.params.id;
        await this.DataFlightFromDB();
        await this.DataLegsFromDB();
        await this.DataPassengerFromDB();
        await this.DataCargoFromDB();
        await this.DataReviseFromDB();
      } else {
        if (this.b_id != "null") {
          await this.DataBookingFromDB();
          await this.DataPassengerFromBookingDB();
          this.DataBookingLegFromDB();
        }
      }

      this.button_view_aircraft_detail = false;

      if (
        this.userdata.r_id != 2 &&
        this.idflight != null &&
        this.itemsDataFlight.pilot != null
      ) {
        this.checkalcohol();
      }
    },
    async checkalcohol() {
      var stringdatetimeerror = this.itemsDataFlight.ap_depart_date;

      let pilotAlcheck = await this.$ProdigyFlyService.GetAlcoholPilot(
        this.itemsDataFlight.pilot.pilot_pcode,
        this.depart_date,
        this.depart_time
      );
      let copilotAlcheck = await this.$ProdigyFlyService.GetAlcoholPilot(
        this.itemsDataFlight.copilot.pilot_pcode,
        this.depart_date,
        this.depart_time
      );
      this.piloterrormess = pilotAlcheck.data.message;
      var x = pilotAlcheck.data.date;
      if (pilotAlcheck.data.message == "Pass") {
        this.piloterrormesstime =
          "Alcohol Test" +
          " " +
          this.piloterrormess +
          "ed @" +
          dayjs(x).format("DD-MM-YYYY HH:mm");
      }
      if (copilotAlcheck.data.message == "Pass") {
        this.copiloterrormesstime =
          "Alcohol Test" +
          " " +
          this.copiloterrormess +
          "ed " +
          dayjs(x).format("DD-MM-YYYY HH:mm");
      }

      this.copiloterrormess = copilotAlcheck.data.message;
    },
    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      } else if (this.userdata.r_id == 14) {
        this.readonly_finance = true;
      } else if (this.userdata.r_id == 5) {
        this.readonly_checkin = true;
      } else if (this.userdata.r_id == 21) {
        this.readonly_super_flight = true;
      }
    },
    // disableInputs(el) {
    //     var el = document.getElementById('flightDetail'),
    //         all = el.getElementsByTagName('*'),
    //         i;
    //     for (i = 0; i < all.length; i++) {
    //         all[i].disabled = true;
    //         all[i].onclick = false;
    //         all[i].event.preventDefault();
    //     }
    // },
    async SelectListPassengerSemi_Manifest() {
      this.listPassengersOut = [];
      this.listPassengersIn = [];

      this.listPassengersOut = [];
      this.listPassengersInterField = [];

      this.ArrListPassengerOut = [];
      this.ArrListPassengerIn = [];
      this.ArrListPassengerInterField = [];
      this.totalBody_KG_Out = 0;
      this.totalBaggage_KG_Out = 0;
      this.sumtotal_KG_Out = 0;
      this.totalBody_KG_In = 0;
      this.totalBaggage_KG_In = 0;
      this.sumtotal_KG_In = 0;
      try {
        var q = {};
        q.retire = "0";
        q.flightid = this.idflight;
        var res = await feathersClientUOA
          .service("reportpassengerbooking")
          .find({ query: q });
        this.ArrListPassengerOut = res[0].Outbound;
        this.ArrListPassengerIn = res[0].Inbound;
        this.ArrListPassengerInterField = res[0].InterField;
        //console.log(JSON.stringify(res[0].InterField));

        //Start header table INTERFIELD
        var headline1 = [
          {
            text: "INTERFIELD",
            colSpan: 8,
            alignment: "center",
            style: "textgrey",
          },
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          {
            text: "Expired date",
            colSpan: 2,
            alignment: "center",
            style: "textgrey",
          },
          "",
          { text: "Remark", rowSpan: 2, style: "textgrey" },
        ];
        this.listPassengersInterField.push(headline1);
        var headline2 = [
          { text: "PAX ID/Passport", style: "textgrey" },
          { text: "No.", style: "textgrey" },
          { text: "Passenger Name", style: "textgrey" },
          { text: "Company", style: "textgrey" },
          { text: "Pax Weight", style: "textgrey" },
          { text: "Bag Weight", style: "textgrey" },
          { text: "From", style: "textgrey" },
          { text: "To", style: "textgrey" },
          { text: "Safety Training", style: "textgrey" },
          { text: "Medical CheckUp", style: "textgrey" },
          { text: "", style: "textgrey" },
        ];
        this.listPassengersInterField.push(headline2);
        //End header table INTERFIELD

        if (this.ArrListPassengerInterField.length == 0) {
          this.listPassengersInterField.push([
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
          ]);
          this.listPassengersInterField.push([
            " ",
            " ",
            "NIL",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
          ]);
          this.listPassengersInterField.push([
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
          ]);
        } else if (this.ArrListPassengerInterField.length <= 6) {
          for (var i = 0; i < 6; i++) {
            if (i < this.ArrListPassengerInterField.length) {
              var hidepassport = "";
              if (this.customer.o_id == 4) {
                hidepassport = "";
              } else {
                hidepassport = this.ArrListPassengerInterField[i].u_idcard_no;
              }
              // this.listPassengersInterField.push([
              //   hidepassport,
              //   i + 1,
              //   this.ArrListPassengerInterField[i].u_name,
              //   this.ArrListPassengerInterField[i].c_name,
              //   this.ArrListPassengerInterField[i].p_body_weight_estimate,
              //   this.ArrListPassengerInterField[i].p_baggage_weight_estimate,
              //   this.ArrListPassengerInterField[i].from,
              //   this.ArrListPassengerInterField[i].to,
              //   format(
              //     new Date(
              //       this.ArrListPassengerInterField[i].u_safety_training
              //     ),
              //     "dd-MMM-yy"
              //   ),
              //   format(
              //     new Date(
              //       this.ArrListPassengerInterField[i].u_medical_checkup
              //     ),
              //     "dd-MMM-yy"
              //   ),
              //   "",
              // ]);

              this.listPassengersInterField.push([
                {
                  text: hidepassport,
                  color: "#FF0000",
                },
                {
                  text: i + 1,
                },
                {
                  text: this.ArrListPassengerInterField[i].u_name,
                },
                {
                  text: this.ArrListPassengerInterField[i].c_name,
                },
                {
                  text: this.ArrListPassengerInterField[i]
                    .p_body_weight_estimate,
                },
                {
                  text: this.ArrListPassengerInterField[i]
                    .p_baggage_weight_estimate,
                },
                {
                  text: this.ArrListPassengerInterField[i].from,
                },
                {
                  text: this.ArrListPassengerInterField[i].to,
                },
                {
                  text: format(
                    new Date(
                      this.ArrListPassengerInterField[i].u_safety_training
                    ),
                    "dd-MMM-yy"
                  ),
                },
                {
                  text: format(
                    new Date(
                      this.ArrListPassengerInterField[i].u_medical_checkup
                    ),
                    "dd-MMM-yy"
                  ),
                },
                {
                  text: "",
                },
              ]);

              this.totalBody_KG_INTERFIELD =
                this.totalBody_KG_INTERFIELD +
                this.ArrListPassengerInterField[i].p_body_weight_estimate;
              this.totalBaggage_KG_INTERFIELD =
                this.totalBaggage_KG_INTERFIELD +
                this.ArrListPassengerInterField[i].p_baggage_weight_estimate;
            } else {
              this.listPassengersInterField.push([
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
              ]);
            }
          }
        } else {
        }
        this.listPassengersInterField.push([
          { text: "", colSpan: 4, border: [false, false, false, false] },
          "",
          "",
          "",
          { text: this.totalBody_KG_INTERFIELD },
          { text: this.totalBaggage_KG_INTERFIELD },
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
          { text: "", colSpan: 2, border: [false, false, false, false] },
          "",
        ]);
        this.listPassengersInterField.push([
          { text: "", colSpan: 4, border: [false, false, false, false] },
          "",
          "",
          "",
          {
            text:
              this.totalBody_KG_INTERFIELD + this.totalBaggage_KG_INTERFIELD,
          },
          { text: "KG" },
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
          { text: "", colSpan: 2, border: [false, false, false, false] },
          "",
        ]);
      } catch (error) {
        console.log(
          "SelectListPassengerSemi_Manifest ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }

      try {
        var q = {};
        if (this.itemsDataFlight.ac_id != undefined) {
          q.ac_id = this.itemsDataFlight.ac_id;

          var res = await feathersClientUOA
            .service("aircrafts")
            .find({ query: q });
          this.aircarftName = res.data[0].ac_name;
        }
      } catch (error) {
        console.log(error);
      }
      for (let j = 0; j < 12; j++) {
        if (j < this.ArrListPassengerOut.length) {
          this.totalBody_KG_Out +=
            this.ArrListPassengerOut[j].p_body_weight_estimate;
          this.totalBaggage_KG_Out +=
            this.ArrListPassengerOut[j].p_baggage_weight_estimate;
          var hidepassport = "";
          if (this.customer.o_id == 4) {
            hidepassport = "";
          } else {
            hidepassport = this.ArrListPassengerOut[j].u_idcard_no;
          }

          //Add
          let u_safety_training = format(
            new Date(this.ArrListPassengerOut[j].u_safety_training),
            "dd-MMM-yy"
          );
          let u_medical_checkup = format(
            new Date(this.ArrListPassengerOut[j].u_medical_checkup),
            "dd-MMM-yy"
          );
          if (this.ArrListPassengerOut[j].u_waive_safety_training) {
            u_safety_training = u_safety_training + " (waive)";
          }
          if (this.ArrListPassengerOut[j].u_waive_medical_checkup) {
            u_medical_checkup = u_medical_checkup + " (waive)";
          }

          this.listPassengersOut.push({
            u_idcard_no: hidepassport,
            No: parseInt(j) + 1,
            u_name: this.ArrListPassengerOut[j].u_name,
            c_name: this.ArrListPassengerOut[j].c_name,
            p_body_weight: this.ArrListPassengerOut[j].p_body_weight_estimate,
            p_baggage_weight:
              this.ArrListPassengerOut[j].p_baggage_weight_estimate,
            from: this.ArrListPassengerOut[j].from,
            to: this.ArrListPassengerOut[j].to,
            // u_safety_training: format(new Date(this.ArrListPassengerOut[j].u_safety_training),"dd-MMM-yy"),
            // u_medical_checkup: format(new Date(this.ArrListPassengerOut[j].u_medical_checkup),"dd-MMM-yy"),
            u_safety_training: u_safety_training,
            u_medical_checkup: u_medical_checkup,
          });
        } else {
          this.listPassengersOut.push({
            u_idcard_no: "",
            No: parseInt(j) + 1,
            u_name: "",
            c_name: "",
            p_body_weight: "",
            p_baggage_weight: "",
            from: "",
            to: "",
            u_safety_training: "",
            u_medical_checkup: "",
          });
        }
      }
      this.totalBody_LBS_Out = (
        parseFloat(this.totalBody_KG_Out) * 2.2
      ).toFixed(0);
      this.totalBaggage_LBS_Out = (
        parseFloat(this.totalBaggage_KG_Out) * 2.2
      ).toFixed(0);

      this.sumtotal_KG_Out = this.totalBody_KG_Out + this.totalBaggage_KG_Out;
      this.sumtotal_LBS_Out =
        parseInt(this.totalBody_LBS_Out) + parseInt(this.totalBaggage_LBS_Out);

      for (let k = 0; k < 12; k++) {
        if (k < this.ArrListPassengerIn.length) {
          this.totalBody_KG_In +=
            this.ArrListPassengerIn[k].p_body_weight_estimate;
          this.totalBaggage_KG_In +=
            this.ArrListPassengerIn[k].p_baggage_weight_estimate;
          var hidepassport = "";
          if (this.customer.o_id == 4) {
            hidepassport = "";
          } else {
            hidepassport = this.ArrListPassengerIn[k].u_idcard_no;
          }

          //Add
          let u_safety_training = format(
            new Date(this.ArrListPassengerIn[k].u_safety_training),
            "dd-MMM-yy"
          );
          let u_medical_checkup = format(
            new Date(this.ArrListPassengerIn[k].u_medical_checkup),
            "dd-MMM-yy"
          );
          if (this.ArrListPassengerIn[k].u_waive_safety_training) {
            u_safety_training = u_safety_training + " (waive)";
          }
          if (this.ArrListPassengerIn[k].u_waive_medical_checkup) {
            u_medical_checkup = u_medical_checkup + " (waive)";
          }

          this.listPassengersIn.push({
            u_idcard_no: hidepassport,
            No: parseInt(k) + 1,
            u_name: this.ArrListPassengerIn[k].u_name,
            c_name: this.ArrListPassengerIn[k].c_name,
            p_body_weight: this.ArrListPassengerIn[k].p_body_weight_estimate,
            p_baggage_weight:
              this.ArrListPassengerIn[k].p_baggage_weight_estimate,
            from: this.ArrListPassengerIn[k].from,
            to: this.ArrListPassengerIn[k].to,
            // u_safety_training: format(new Date(this.ArrListPassengerIn[k].u_safety_training),"dd-MMM-yy"),
            // u_medical_checkup: format(new Date(this.ArrListPassengerIn[k].u_medical_checkup),"dd-MMM-yy"),
            u_safety_training: u_safety_training,
            u_medical_checkup: u_medical_checkup,
          });
        } else {
          this.listPassengersIn.push({
            u_idcard_no: "",
            No: parseInt(k) + 1,
            u_name: "",
            c_name: "",
            p_body_weight: "",
            p_baggage_weight: "",
            from: "",
            to: "",
            u_safety_training: "",
            u_medical_checkup: "",
          });
        }
      }

      this.totalBody_LBS_In = (parseFloat(this.totalBody_KG_In) * 2.2).toFixed(
        0
      );
      this.totalBaggage_LBS_In = (
        parseFloat(this.totalBaggage_KG_In) * 2.2
      ).toFixed(0);

      this.sumtotal_KG_In = this.totalBody_KG_In + this.totalBaggage_KG_In;
      this.sumtotal_LBS_In =
        parseInt(this.totalBody_LBS_In) + parseInt(this.totalBaggage_LBS_In);
    },
    async SelectListAirport() {
      try {
        // var q = {};
        // q.$sort = {
        //   ap_id: 1,
        // };
        // q.retire = 0;
        // var res = await feathersClientUOA
        //   .service("airports")
        //   .find({ query: q });
        this.itemsListAirport = this.master.airport;
        //Default create flight no booking
        this.departureairport = this.itemsListAirport[0];
        this.destinationairport = this.itemsListAirport[0];
        this.itemsListLeg = this.master.airport;
      } catch (error) {
        console.log(
          "SelectListAirport ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    async SelectListAircraft() {
      try {
        this.itemsListAircraft = this.master.aircraft;
      } catch (error) {
        console.log(
          "SelectListAircraft ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    async SelectListPilot() {
      try {
        this.itemsPilot = this.master.pilot;
        this.itemsCoPilot = this.master.pilot;
        for (let index = 0; index < this.itemsPilot.length; index++) {
          const elementId = this.itemsPilot[index].pl_id;
          const element = this.itemsPilot[index].pl_name;
          this.itemsListPilot.push({ pl_id: elementId, pl_name: element });
        }
      } catch (error) {
        console.log(
          "SelectListPilot ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    async SelectListTerminal() {
      try {
        // var q = {};
        // q.$sort = {
        //   tm_id: 1,
        // };
        // q.retire = '0';
        // var res = await feathersClientUOA
        //   .service("terminal")
        //   .find({ query: q });
        this.itemsListTerminal = this.master.terminal;
        this.terminal = this.itemsListTerminal[0];
      } catch (error) {
        console.log(
          "SelectListTerminal ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    async SelectListCheckInVideo() {
      try {
        // var q = {};
        // q.$sort = {
        //   v_id: 1,
        // };
        // var res = await feathersClientUOA
        //   .service("filevideo")
        //   .find({ query: q });
        this.itemsListCheckInVideo = this.master.briefCheckInVideo;
        this.videoCheckin = this.itemsListCheckInVideo[0];
      } catch (error) {
        console.log(
          "SelectListCheckInVideo ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    async SelectListFlightStatus() {
      try {
        // var q = {};
        // q.$sort = {
        //   fs_id: 1,
        // };
        // //Add query: {$sort: { fs_id: 1}} นอกไปมาก
        // var res = await feathersClientUOA
        //   .service("flightstatus")
        //   .find({ query: q });
        this.itemsListFlightStatus = this.master.flightStatus;
        this.flightstatus = this.itemsListFlightStatus[0];
      } catch (error) {
        console.log(
          "SelectListFlightStatus ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    //Edit
    async SelectListFlightType() {
      try {
        var q = {};
        // var res = await feathersClientUOA.service("flighttype").find({});
        this.itemsListFlightType = this.master.flightType;
        this.flighttype = this.itemsListFlightType[0];
      } catch (error) {
        console.log(
          "SelectListFlightType ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },

    async SelectListCustomer() {
      try {
        //var res = await feathersClientUOA.service("customer").find({});
        this.itemsListCustomer = this.master.customer;
        this.customer = this.itemsListCustomer[0];
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    async DataFlightFromDB() {
      try {
        var q = {};
        q.f_id = this.idflight;
        q.$eager = "[pilot, copilot, customer, aircrafts]";
        var res = await feathersClientUOA.service("flight").find({ query: q });
        this.itemsDataFlight = res.data[0];
        this.customerPassenger = this.itemsDataFlight.customer;
        this.depart_date = this.itemsDataFlight.ap_depart_date.substring(0, 10);
        this.depart_time = this.itemsDataFlight.ap_depart_date.substring(
          11,
          16
        );
        this.dest_date = this.itemsDataFlight.ap_dest_date.substring(0, 10);
        this.dest_time = this.itemsDataFlight.ap_dest_date.substring(11, 16);

        if (this.itemsDataFlight.tm_id != null) {
          this.terminal = this.itemsDataFlight.tm_id;
        }
        if (this.itemsDataFlight.v_id != null) {
          this.videoCheckin = this.itemsDataFlight.v_id;
        }

        this.flightno = this.itemsDataFlight.f_flight_no;

        this.customer = {
          o_id: this.itemsDataFlight.customer.o_id,
          o_name: this.itemsDataFlight.customer.o_name,
        };

        this.flighttype = {
          ft_id: this.itemsDataFlight.ft_id,
          ft_name: this.itemsDataFlight.f_type_of_flight,
        };

        if (this.flighttype.ft_id == 1) {
          this.itemsListFlightType = this.itemsListFlightType.filter(x => x.ft_id == 1);
        } else {
          this.itemsListFlightType = this.itemsListFlightType.filter(x => x.ft_id != 1);
        }

        this.flightstatus = this.itemsDataFlight.fs_id;

        if (this.itemsDataFlight.f_pilot_id != null) {
          if (this.itemsPilot.filter(x => x.pl_id == this.itemsDataFlight.f_pilot_id).length == 1) {
            this.pilot = {
              pl_id: this.itemsDataFlight.f_pilot_id,
              pl_name: this.itemsDataFlight.f_pilot_name + " [" + this.itemsPilot.filter(x => x.pl_id == this.itemsDataFlight.f_pilot_id)[0].pl_weight_kg + " kg]",
              pilot_rank: this.itemsDataFlight.pilot.pilot_rank,
              pl_weight_kg: this.itemsPilot.filter(x => x.pl_id == this.itemsDataFlight.f_pilot_id)[0].pl_weight_kg
            };
          } else {
            this.pilot = {
              pl_id: this.itemsDataFlight.f_pilot_id,
              pl_name: this.itemsDataFlight.f_pilot_name + " [0 kg]",
              pilot_rank: this.itemsDataFlight.copilot.pilot_rank,
              pl_weight_kg: 0
            };
          }
        }

        if (this.itemsDataFlight.f_copilot_id != null) {
          if (this.itemsCoPilot.filter(x => x.pl_id == this.itemsDataFlight.f_copilot_id).length == 1) {
            this.copilot = {
              pl_id: this.itemsDataFlight.f_copilot_id,
              pl_name: this.itemsDataFlight.f_copilot_name + " [" + this.itemsCoPilot.filter(x => x.pl_id == this.itemsDataFlight.f_copilot_id)[0].pl_weight_kg + " kg]",
              pilot_rank: this.itemsDataFlight.copilot.pilot_rank,
              pl_weight_kg: this.itemsCoPilot.filter(x => x.pl_id == this.itemsDataFlight.f_copilot_id)[0].pl_weight_kg
            };
          } else {
            this.copilot = {
              pl_id: this.itemsDataFlight.f_copilot_id,
              pl_name: this.itemsDataFlight.f_copilot_name + " [0 kg]",
              pilot_rank: this.itemsDataFlight.copilot.pilot_rank,
              pl_weight_kg: 0
            };
          }
        }

        if (this.itemsDataFlight.f_pilot_id != null) {
          await this.CalPilotRank(this.pilot);
        }

        if (this.itemsDataFlight.f_copilot_id != null) {
          await this.CalCoPilotRank(this.copilot);
        }

        if (this.itemsDataFlight.f_pilot3_id != null) {
          this.pilot3 = {
            pl_id: this.itemsDataFlight.f_pilot3_id,
            pl_name: this.itemsDataFlight.f_pilot3_name,
          };
        }
        if (this.itemsDataFlight.f_pilot3_ispilot != null) {
          this.pilot3IsPilot = this.itemsIsPilot.filter(x => x.pt_id == this.itemsDataFlight.f_pilot3_ispilot)[0];
        }

        if (this.itemsDataFlight.f_pilot4_id != null) {
          this.pilot4 = {
            pl_id: this.itemsDataFlight.f_pilot4_id,
            pl_name: this.itemsDataFlight.f_pilot4_name,
          };
        }
        if (this.itemsDataFlight.f_pilot4_ispilot != null) {
          this.pilot4IsPilot = this.itemsIsPilot.filter(x => x.pt_id == this.itemsDataFlight.f_pilot4_ispilot)[0];
        }

        if (this.itemsDataFlight.ac_id != null) {
          this.aircraft = {
            ac_id: this.itemsDataFlight.aircrafts.ac_id,
            ac_name: this.itemsDataFlight.aircrafts.ac_name,
          };
          this.aircraftBackup = this.itemsDataFlight.f_aircraft_backup;
        }

        this.departureairport = this.itemsDataFlight.ap_departairport_id;
        this.destinationairport = this.itemsDataFlight.ap_destairport_id;
        this.depart_visibility =
          this.itemsDataFlight.f_depart_weather_visibility;
        this.depart_oat = this.itemsDataFlight.f_depart_weather_oat;
        this.depart_wind_spd = this.itemsDataFlight.f_depart_weather_wind_spd;
        this.depart_qnh = this.itemsDataFlight.f_depart_weather_qnh;
        this.dest_visibility = this.itemsDataFlight.f_dest_weather_visibility;
        this.dest_oat = this.itemsDataFlight.f_dest_weather_oat;
        this.dest_wind_spd = this.itemsDataFlight.f_dest_weather_wind_spd;
        this.dest_qnh = this.itemsDataFlight.f_dest_weather_qnh;

        if (this.itemsDataFlight.f_actual_engine_start != null) {
          this.actual_engine_start_date =
            this.itemsDataFlight.f_actual_engine_start.substring(0, 10);
          this.actual_engine_start_time =
            this.itemsDataFlight.f_actual_engine_start.substring(11, 16);
        }
        this.available_payload = this.itemsDataFlight.f_available_payload;
        this.available_payload_lbs = this.itemsDataFlight.f_available_payload_lbs;

        this.FHBasic = {
          ID: this.itemsDataFlight.FH_basis,
          Name: this.itemsDataFlight.FH_basis,
        };
        this.UOANote = this.itemsDataFlight.f_uoanote;
      } catch (error) {
        console.log(error);
      }

      this.divFD = this.f_id === null ? "status FD" : "status FD active";
      this.divEN = this.aircraft === null ? "status EN" : "status EN active";
      this.divPI =
        (this.pilot === "" && this.copilot === "") ||
          (this.pilot === null && this.copilot === null)
          ? "status PI"
          : "status PI active";
      //this.divCTE = this.aircraft !== "" && ((this.pilot === "" && this.copilot === "")||(this.pilot === null && this.copilot === null))  ? "status CTE active" : "status CTE "
      this.divCTE =
        this.divFD == "status FD active" &&
          this.divEN == "status EN active" &&
          this.divPI == "status PI active"
          ? "status CTE active"
          : "status CTE ";
    },
    async DataLegsFromDB() {
      this.listAddLegs = [];
      try {
        var q = {};
        q.f_id = this.idflight;
        q.$eager = "[airport]";
        q.retire = "0";
        var res = await feathersClientUOA.service("leg").find({ query: q });
        this.itemsDataLegs = res.data;

        this.itemsDataLegs.forEach((element) => {
          var newArrayBinding = {};
          newArrayBinding["activity1"] =
            element.l_activity1 === "1" ? true : false;
          newArrayBinding["activity2"] =
            element.l_activity2 === "1" ? true : false;
          newArrayBinding["activity3"] =
            element.l_activity3 === "1" ? true : false;
          newArrayBinding["activity4"] =
            element.l_activity4 === "1" ? true : false;
          newArrayBinding["activity5"] =
            element.l_activity5 === "1" ? true : false;
          newArrayBinding["Idflightlegs"] = element.l_id;

          if (element.l_eta != null) {
            // newArrayBinding["l_eta"] = element.l_eta.substring(0, 5);
            //Edit
            newArrayBinding["l_eta"] = element.l_eta.substring(11, 16);
          }
          if (element.l_etd != null) {
            //newArrayBinding["l_etd"] = element.l_etd.substring(0, 5);
            //Edit
            newArrayBinding["l_etd"] = element.l_etd.substring(11, 16);
          }
          newArrayBinding["l_weather_oat"] = element.l_weather_oat;
          newArrayBinding["l_weather_qnh"] = element.l_weather_qnh;
          newArrayBinding["l_weather_visibility"] =
            element.l_weather_visibility;
          newArrayBinding["l_weather_wind_spd"] = element.l_weather_wind_spd;
          newArrayBinding["legs"] = element.airport;
          this.listAddLegs.push(newArrayBinding);
        });
      } catch (error) {
        console.log(
          "DataLegsFromDB ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }

      var NewdataLeg = "-";
      for (let j = 0; j < this.itemsDataLegs.length; j++) {
        NewdataLeg += this.itemsDataLegs[j].ap_name;
        if (this.itemsDataLegs[j].l_activity1 == 1) {
          NewdataLeg += "(S/D Refuel)";
        }

        if (this.itemsDataLegs[j].l_activity2 == 1) {
          NewdataLeg += "(S/D Refuel and HLO familiarize)";
        }

        if (this.itemsDataLegs[j].l_activity3 == 1) {
          NewdataLeg += "(S/D FOR JET A1 INSPECTION)";
        }

        if (this.itemsDataLegs[j].l_activity4 == 1) {
          NewdataLeg += "(S/D for Helideck Inspec)";
        }

        if (this.itemsDataLegs[j].l_activity5 == 1) {
          NewdataLeg += "(S/D";
        }

        NewdataLeg += "-";
      }
      this.dataLegs = "";
      this.dataLegs = NewdataLeg;
    },
    async DataPassengerFromDB() {
      try {
        this.sumPassengerOut =
          await this.$refs.CompAddPassengerOutbound2.DataPassengerFromDB(
            this.idflight
          );
      } catch (error) {
        console.log(error);
      }
      try {
        this.sumPassengerIn =
          await this.$refs.CompAddPassengerInbound2.DataPassengerFromDB(
            this.idflight
          );
      } catch (error) {
        console.log(error);
      }
    },

    async DataReviseFromDB() {
      try {
        this.sumPassengerOut =
          await this.$refs.CompAddReviseFlight.DataReviseFromDB(this.idflight);
      } catch (error) {
        console.log(error);
      }
    },

    async DataBookingFromDB() {
      try {
        var q = {};
        q.b_id = this.b_id;
        var res = await feathersClientUOA.service("booking").find({ query: q });
        this.itemsDataBooking = res.data[0];
        this.ref_id = this.itemsDataBooking.ref_id;
        this.flightno = this.itemsDataBooking.f_no;
        this.customer = this.itemsDataBooking.o_id;

        //Default
        this.flighttype = this.itemsListFlightType[0];
        this.terminal = this.itemsListTerminal[0];
        this.videoCheckin = this.itemsListCheckInVideo[0];
        this.departureairport = this.itemsDataBooking.ap_id;
        this.depart_date = this.itemsDataBooking.b_departure_date.substring(
          0,
          10
        );
        this.depart_time = this.itemsDataBooking.b_departure_date.substring(
          11,
          16
        );

        //Default กับขาออก
        this.destinationairport = { ap_id: this.itemsDataBooking.ap_id };

        this.dest_date = this.itemsDataBooking.b_departure_date.substring(
          0,
          10
        );
        this.dest_time = this.itemsDataBooking.b_departure_date.substring(
          11,
          16
        );

        this.flightstatus = this.itemsListFlightStatus[0];
      } catch (error) {
        console.log(
          "DataBookingFromDB ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    async DataBookingLegFromDB() {
      try {
        var q = {};
        q.b_id = this.b_id;
        q.$eager = "[airport]";
        q.retire = "0";
        var res = await feathersClientUOA
          .service("bookingleg")
          .find({ query: q });
        this.itemsDataLegs = res.data;
        this.itemsDataLegs.forEach((element) => {
          var newArrayBinding = {};
          newArrayBinding["activity1"] =
            element.bl_activity1 === "1" ? true : false;
          newArrayBinding["activity2"] =
            element.bl_activity2 === "1" ? true : false;
          newArrayBinding["activity3"] =
            element.bl_activity3 === "1" ? true : false;
          newArrayBinding["activity4"] =
            element.bl_activity4 === "1" ? true : false;
          newArrayBinding["activity5"] =
            element.bl_activity5 === "1" ? true : false;
          newArrayBinding["Idflightlegs"] = null;
          newArrayBinding["l_eta"] = "";
          newArrayBinding["l_etd"] = "";
          newArrayBinding["l_weather_oat"] = "";
          newArrayBinding["l_weather_qnh"] = "";
          newArrayBinding["l_weather_visibility"] = "";
          newArrayBinding["l_weather_wind_spd"] = "";
          newArrayBinding["legs"] = element.airport;
          this.listAddLegs.push(newArrayBinding);
        });
      } catch (error) {
        console.log(
          "DataBookingLegFromDB ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    async DataPassengerFromBookingDB() {
      try {
        await this.$refs.CompAddPassengerOutbound2.DataPassengerFromBookingDB(
          this.b_id
        );
      } catch (error) {
        console.log(error);
      }
      try {
        await this.$refs.CompAddPassengerInbound2.DataPassengerFromBookingDB(
          this.b_id
        );
      } catch (error) {
        console.log(error);
      }
    },
    async DataCargoFromDB() {
      this.listAddCargo = [];
      this.itemsDataCargo = [];
      try {
        var q = {};
        q.f_id = this.idflight;
        q.$eager = "[airport]";
        q.retire = "0";
        var res = await feathersClientUOA.service("cargo").find({ query: q });
        this.itemsDataCargo = res.data;
        this.itemsDataCargo.forEach((element) => {
          var newArrayBinding = {};
          newArrayBinding["Idflightcargo"] = element.cg_id;
          newArrayBinding["cg_dan_no"] = element.cg_dan_no;
          newArrayBinding["cg_weight"] = element.cg_weight;
          newArrayBinding["cg_contact"] = element.cg_contact;
          newArrayBinding["cg_remark"] = element.cg_remark;
          newArrayBinding["Destinations"] = element.airport;
          this.listAddCargo.push(newArrayBinding);
        });
        this.SumDataCargo();
        this.SumDataTotal();
      } catch (error) {
        console.log(
          "DataCargoFromDB ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    async SaveLegs() {
      if (this.modeEvent == "create") {
        var dataAdd = {
          f_id: this.idflight,
          ap_id: this.legs.ap_id,
          ap_name: this.legs.ap_name,
          ap_color: this.legs.ap_color,
          l_activity1: this.activity1 === false ? 0 : 1,
          l_activity2: this.activity2 === false ? 0 : 1,
          l_activity3: this.activity3 === false ? 0 : 1,
          l_activity4: this.activity4 === false ? 0 : 1,
          l_activity5: this.activity5 === false ? 0 : 1,
          l_weather_visibility: this.l_weather_visibility,
          l_weather_oat: this.l_weather_oat,
          l_weather_wind_spd: this.l_weather_wind_spd,
          l_weather_qnh: this.l_weather_qnh,
          l_etd: this.l_etd,
          l_eta: this.l_eta,
          retire: 0,
        };
        await this.CreateLegs(dataAdd);
        this.DataLegsFromDB();
      } else {
        //Edit ปิดแล้วแก้ไขเป็นเพิ่ม ลบ แก้ไขทันที (แก้ไข)
        let dataUpdate = {
          ap_id: this.legs.ap_id,
          ap_name: this.legs.ap_name,
          ap_color: this.legs.ap_color,
          l_activity1: this.activity1 === false ? 0 : 1,
          l_activity2: this.activity2 === false ? 0 : 1,
          l_activity3: this.activity3 === false ? 0 : 1,
          l_activity4: this.activity4 === false ? 0 : 1,
          l_activity5: this.activity5 === false ? 0 : 1,
          l_weather_visibility: this.l_weather_visibility,
          l_weather_oat: this.l_weather_oat,
          l_weather_wind_spd: this.l_weather_wind_spd,
          l_weather_qnh: this.l_weather_qnh,
          l_etd: this.l_etd,
          l_eta: this.l_eta,

          retire: 0,
        };
        await this.Updatetodb(this.idflightlegs, dataUpdate);
        this.DataLegsFromDB();
      }

      this.dialogEvent = false;
    },
    async SaveCargo() {
      if (this.modeEvent == "create") {
        let DataAdd = {
          f_id: this.idflight,
          cg_dan_no: this.cg_dan_no,
          cg_weight: this.cg_weight,
          cg_contact: this.cg_contact,
          cg_remark: this.cg_remark,
          ap_id: this.Destinations.ap_id,
          retire: 0,
        };
        await this.CreateCargo(DataAdd);
        this.DataCargoFromDB();
      } else {
        let cargoID = this.listAddCargo[this.chooseItemCargoIndex].Idflightcargo;
        let dataUpdate = {
          cg_dan_no: this.cg_dan_no,
          cg_weight: this.cg_weight,
          cg_contact: this.cg_contact,
          cg_remark: this.cg_remark,
          ap_id: this.Destinations.ap_id,
          retire: 0,
        };
        await this.UpdateCargotodb(cargoID, dataUpdate);
        this.DataCargoFromDB();
      }
      this.dialogCargo = false;
    },
    async addItem() {
      this.modeEvent = "create";
      this.dialogEvent = true;
      this.legs = "";
      this.activity1 = false;
      this.activity2 = false;
      this.activity3 = false;
      this.activity4 = false;
      this.activity5 = false;
      this.l_eta = "00:00";
      this.l_etd = "00:00";
      this.l_weather_oat = "";
      this.l_weather_qnh = "";
      this.l_weather_visibility = "";
      this.l_weather_wind_spd = "";

      this.legs = this.itemsListAirport[0];
    },
    async EditItem(itemlegs, index) {
      this.idflightlegs = null;
      this.idflightlegs = itemlegs.Idflightlegs;
      this.modeEvent = "edit";
      this.dialogEvent = true;
      this.legs = itemlegs.legs;
      this.activity1 = itemlegs.activity1;
      this.activity2 = itemlegs.activity2;
      this.activity3 = itemlegs.activity3;
      this.activity4 = itemlegs.activity4;
      this.activity5 = itemlegs.activity5;
      this.chooseItemLegs = itemlegs;
      this.chooseItemLegIndex = index;
      this.l_eta = itemlegs.l_eta;
      this.l_etd = itemlegs.l_etd;
      this.l_weather_oat = itemlegs.l_weather_oat;
      this.l_weather_qnh = itemlegs.l_weather_qnh;
      this.l_weather_visibility = itemlegs.l_weather_visibility;
      this.l_weather_wind_spd = itemlegs.l_weather_wind_spd;
    },
    async deleteItem(itemlegs) {
      try {
        await feathersClientUOA.service("leg").remove(itemlegs.Idflightlegs);
        await this.DataLegsFromDB();
        await this.CompareLeg();
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          Idflightlegs: itemlegs.Idflightlegs
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "DeleteLegFromFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("DeleteLegFromFlight, Error : {error}", { error });
        console.log("DeleteLegFromFlight, Error : {error}" + error);
      }
    },
    async addItemCargo() {
      this.modeEvent = "create";
      this.dialogCargo = true;
      this.Destinations = "";
      this.cg_dan_no = "";
      this.cg_weight = "";
      this.cg_contact = "";
      this.cg_remark = "";

      this.Destinations = this.itemsListAirport[0];
    },
    async EditItemCargo(itemCargo, index) {
      this.modeEvent = "edit";

      this.dialogCargo = true;
      this.Destinations = itemCargo.Destinations;
      this.cg_dan_no = itemCargo.cg_dan_no;
      this.cg_weight = itemCargo.cg_weight;
      this.cg_contact = itemCargo.cg_contact;
      this.cg_remark = itemCargo.cg_remark;
      this.chooseItemCargo = itemCargo;
      this.chooseItemCargoIndex = index;
    },
    async deleteItemCargo(itemCargo) {
      try {
        let cargoID = itemCargo.Idflightcargo;
        await feathersClientUOA.service("cargo").remove(cargoID);
        this.DataCargoFromDB();
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          cg_id: cargoID,
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, cg_id={cg_id}', { action: "DeleteCargoFromFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("DeleteCargoFromFlight, Error : {error}", { error });
        console.log("DeleteCargoFromFlight, Error : {error}" + error);
      }
    },
    async SaveEditToDB() {
      try {
        //Add
        this.buttonCancel = true;
        this.buttonSave = true;
        var dataUpdate = {
          f_flight_no: this.flightno,
          tm_id: this.terminal.tm_id,
          v_id: this.videoCheckin.v_id,
          o_id: this.customer.o_id,
          ap_depart_date: this.depart_date + " " + this.depart_time,
          ap_dest_date: this.dest_date + " " + this.dest_time,
          ft_id: this.flighttype.ft_id,
          f_type_of_flight: this.flighttype.ft_name,

          fs_id: this.flightstatus.fs_id,
          ap_departairport_id: this.departureairport.ap_id,

          ap_destairport_id: this.destinationairport.ap_id,

          f_depart_weather_visibility: this.depart_visibility,
          f_depart_weather_oat: this.depart_oat,
          f_depart_weather_wind_spd: this.depart_wind_spd,
          f_depart_weather_qnh: this.depart_qnh,
          f_dest_weather_visibility: this.dest_visibility,
          f_dest_weather_oat: this.dest_oat,
          f_dest_weather_wind_spd: this.dest_wind_spd,
          f_dest_weather_qnh: this.dest_qnh,
          //f_available_payload: this.available_payload,
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),

          //Add 20220324
          FH_basis: this.FHBasic.ID,
          f_uoanote: this.UOANote,
        };

        if (this.actual_engine_start_date != "") {
          dataUpdate.f_actual_engine_start =
            this.actual_engine_start_date + " " + this.actual_engine_start_time;
        } else {
          dataUpdate.f_actual_engine_start = null;
        }

        var res = await feathersClientUOA
          .service("flight")
          .patch(this.idflight, dataUpdate);

        this.dialogSave = false;
        this.dialogConfirm = true;
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          f_flight_no: this.flightno,
          o_id: dataUpdate.o_id,
          ft_id: dataUpdate.ft_id,
          ft_name: dataUpdate.f_type_of_flight,
          tm_id: dataUpdate.tm_id,
          v_id: dataUpdate.v_id,
          fs_id: dataUpdate.fs_id,
          FH_basis: dataUpdate.FH_basis,
          f_uoanote: dataUpdate.f_uoanote,
          ap_departairport_id: this.departureairport.ap_id,
          ap_departairport_name: this.departureairport.ap_name,
          ap_depart_date: dataUpdate.ap_depart_date,
          ap_destairport_id: this.destinationairport.ap_id,
          ap_destairport_name: this.destinationairport.ap_name,
          ap_dest_date: dataUpdate.ap_dest_date
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "EditFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("EditFlight, Error : {error}", { error });
        console.log("EditFlight, Error : {error}" + error);
      }
    },
    async SaveEditToDB_Role_En() {
      try {
        var DataAdd = {
          ac_id: this.aircraft.ac_id,
          f_aircraft_backup: this.aircraftBackup,
          f_assing_aircraft_by: this.userdata.FullName,
          f_assing_aircraft_date: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        };
        var res = await feathersClientUOA.service("flight").patch(this.idflight, DataAdd);
        this.dialogSaveE = false;
        this.dialogConfirm = true;
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          ac_id: DataAdd.ac_id,
          ac_name: this.aircraft.ac_name,
          f_aircraft_backup: DataAdd.f_aircraft_backup,
          f_assing_aircraft_by: DataAdd.f_assing_aircraft_by,
          f_assing_aircraft_date: DataAdd.f_assing_aircraft_date,
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "AddAircraftToFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddAircraftToFlight, Error : {error}", { error });
        console.log("AddAircraftToFlight, Error : {error}" + error);
      }
    },
    async SaveEditToDB_Role_Pi() {
      try {
        let pilotId = null;
        let pilotName = null;
        if (this.pilot != null) {
          pilotId = this.pilot.pl_id;
          let arrayPilot = this.pilot.pl_name.split(" [");
          pilotName = arrayPilot[0].trim();
        }

        let coPilotId = null;
        let coPilotName = null;
        if (this.copilot != null) {
          coPilotId = this.copilot.pl_id;
          let arrayCoPilot = this.copilot.pl_name.split(" [");
          coPilotName = arrayCoPilot[0].trim();
        }

        let pilot3Id = null;
        let pilot3Name = null;
        if (this.pilot3 != null) {
          pilot3Id = this.pilot3.pl_id;
          let arrayPilot3 = this.pilot3.pl_name.split(" [");
          pilot3Name = arrayPilot3[0].trim();
        }
        let pilot3IsPilot = null;
        if (this.pilot3IsPilot != null) {
          pilot3IsPilot = this.pilot3IsPilot.pt_id;
        }

        let pilot4Id = null;
        let pilot4Name = null;
        if (this.pilot4 != null) {
          pilot4Id = this.pilot4.pl_id;
          let arrayPilot4 = this.pilot4.pl_name.split(" [");
          pilot4Name = arrayPilot4[0].trim();
        }
        let pilot4IsPilot = null;
        if (this.pilot4IsPilot != null) {
          pilot4IsPilot = this.pilot4IsPilot.pt_id;
        }

        var DataAdd = {
          f_pilot_id: pilotId,
          f_pilot_name: pilotName,

          f_copilot_id: coPilotId,
          f_copilot_name: coPilotName,

          f_assing_pilot_by: this.userdata.FullName,
          f_assing_pilot_date: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),

          f_pilot3_id: pilot3Id,
          f_pilot3_name: pilot3Name,
          f_pilot3_ispilot: pilot3IsPilot,
          f_pilot4_id: pilot4Id,
          f_pilot4_name: pilot4Name,
          f_pilot4_ispilot: pilot4IsPilot,
        };

        var res = await feathersClientUOA.service("flight").patch(this.idflight, DataAdd);
        this.dialogSaveP = false;
        this.dialogConfirm = true;

        //SEQ
        let properties = {
          f_id: this.idflight,
          f_pilot_id: DataAdd.f_pilot_id,
          f_pilot_name: DataAdd.f_pilot_name,
          f_copilot_id: DataAdd.f_copilot_id,
          f_copilot_name: DataAdd.f_copilot_name,
          f_assing_pilot_by: DataAdd.f_assing_pilot_by,
          f_assing_pilot_date: DataAdd.f_assing_pilot_date,
          f_pilot3_id: DataAdd.f_pilot3_id,
          f_pilot3_name: DataAdd.f_pilot3_name,
          f_pilot3_ispilot: DataAdd.f_pilot3_ispilot,
          f_pilot4_id: DataAdd.f_pilot4_id,
          f_pilot4_name: DataAdd.f_pilot4_name,
          f_pilot4_ispilot: DataAdd.f_pilot4_ispilot
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "AddPilotAndCoPilotToFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddPilotAndCoPilotToFlight, Error : {error}", { error });
        console.log("AddPilotAndCoPilotToFlight, Error : {error}" + error);
      }
    },
    async SaveLegsEditToDB() {
      var queryflight = {
        f_id: this.idflight,
      };
      var res = await feathersClientUOA
        .service("leg")
        .find({ query: queryflight });
      var DataAdd = {
        retire: 1,
      };

      try {
        res.data.forEach((element) => {
          this.Updatetodb(element.l_id, DataAdd);
        });
      } catch (error) {
        console.log(error);
      }
      this.listAddLegs.forEach((elementUpdate) => {
        if (elementUpdate.Idflightlegs != null) {
          let dataUpdate = {
            ap_id: elementUpdate.legs.ap_id,
            ap_name: elementUpdate.legs.ap_name,
            ap_color: elementUpdate.legs.ap_color,
            l_activity1: elementUpdate.activity1 === false ? 0 : 1,
            l_activity2: elementUpdate.activity2 === false ? 0 : 1,
            l_activity3: elementUpdate.activity3 === false ? 0 : 1,
            l_activity4: elementUpdate.activity4 === false ? 0 : 1,
            l_activity5: elementUpdate.activity5 === false ? 0 : 1,
            l_weather_visibility: elementUpdate.l_weather_visibility,
            l_weather_oat: elementUpdate.l_weather_oat,
            l_weather_wind_spd: elementUpdate.l_weather_wind_spd,
            l_weather_qnh: elementUpdate.l_weather_qnh,
            l_etd: elementUpdate.l_etd,
            l_eta: elementUpdate.l_eta,

            retire: 0,
          };

          //Add
          if (DataAdd.l_etd == "") {
            DataAdd.l_etd = null;
          }

          if (DataAdd.l_eta == "") {
            DataAdd.l_eta = null;
          }

          this.Updatetodb(elementUpdate.Idflightlegs, dataUpdate);
        } else {
          let DataAdd = {
            f_id: this.idflight,
            ap_id: elementUpdate.legs.ap_id,
            ap_name: elementUpdate.legs.ap_name,
            ap_color: elementUpdate.legs.ap_color,
            l_activity1: elementUpdate.activity1 === false ? 0 : 1,
            l_activity2: elementUpdate.activity2 === false ? 0 : 1,
            l_activity3: elementUpdate.activity3 === false ? 0 : 1,
            l_activity4: elementUpdate.activity4 === false ? 0 : 1,
            l_activity5: elementUpdate.activity5 === false ? 0 : 1,
            l_weather_visibility: elementUpdate.l_weather_visibility,
            l_weather_oat: elementUpdate.l_weather_oat,
            l_weather_wind_spd: elementUpdate.l_weather_wind_spd,
            l_weather_qnh: elementUpdate.l_weather_qnh,
            l_etd: elementUpdate.l_etd,
            l_eta: elementUpdate.l_eta,
            retire: 0,
          };

          //Add
          if (DataAdd.l_etd == "") {
            DataAdd.l_etd = null;
          }

          if (DataAdd.l_eta == "") {
            DataAdd.l_eta = null;
          }

          this.CreateLegs(DataAdd);
        }
      });
    },

    async Updatetodb(Idlegs, dataUpdate) {
      try {
        await feathersClientUOA.service("leg").patch(Idlegs, dataUpdate);
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          l_id: Idlegs,
          ap_id: dataUpdate.ap_id,
          ap_name: dataUpdate.ap_name,
          ap_color: dataUpdate.ap_color,
          l_activity1: dataUpdate.l_activity1,
          l_activity2: dataUpdate.l_activity2,
          l_activity3: dataUpdate.l_activity3,
          l_activity4: dataUpdate.l_activity4,
          l_activity5: dataUpdate.l_activity5,
          l_weather_visibility: dataUpdate.l_weather_visibility,
          l_weather_oat: dataUpdate.l_weather_oat,
          l_weather_wind_spd: dataUpdate.l_weather_wind_spd,
          l_weather_qnh: dataUpdate.l_weather_qnh,
          l_etd: dataUpdate.l_etd,
          l_eta: dataUpdate.l_eta
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "EditLegInFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("EditLegInFlight, Error : {error}", { error });
        console.log("EditLegInFlight, Error : {error}" + error);
      }
    },
    async UpdateCargotodb(Idcargo, dataUpdate) {
      try {
        await feathersClientUOA.service("cargo").patch(Idcargo, dataUpdate);
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          cg_id: Idcargo,
          cg_dan_no: dataUpdate.cg_dan_no,
          cg_weight: dataUpdate.cg_weight,
          cg_contact: dataUpdate.cg_contact,
          cg_remark: dataUpdate.cg_remark,
          ap_id: dataUpdate.ap_id
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, cg_id={cg_id}', { action: "EditCargoToFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("EditCargoToFlight, Error : {error}", { error });
        console.log("EditCargoToFlight, Error : {error}" + error);
      }
    },
    async CreateFlight() {
      if (this.b_id == "null") {
        this.CreateFlightWithoutBookingId();
      } else {
        try {
          var DataAdd = {
            //Edit ปิด Only Pilot
            // ac_id :this.aircarftName,
            // ac_id: this.aircraft.ac_id,

            f_flight_no: this.flightno,
            tm_id: this.terminal.tm_id,
            v_id: this.terminal.v_id,
            // c_id: this.company.c_id,
            //Edit
            o_id: this.customer.o_id,

            ap_depart_date: this.depart_date + " " + this.depart_time,
            ap_dest_date: this.dest_date + " " + this.dest_time,

            // f_type_of_flight: this.flighttype,
            ft_id: this.flighttype.ft_id,
            f_type_of_flight: this.flighttype.ft_name,

            fs_id: this.flightstatus.fs_id,

            //Edit ปิด Only Pilot
            // f_pilot_name: this.pilot,
            // f_copilot_name: this.copilot,

            ap_departairport_id: this.departureairport.ap_id,

            ap_destairport_id: this.destinationairport.ap_id,

            f_depart_weather_visibility: this.depart_visibility,
            f_depart_weather_oat: this.depart_oat,
            f_depart_weather_wind_spd: this.depart_wind_spd,
            f_depart_weather_qnh: this.depart_qnh,
            f_dest_weather_visibility: this.dest_visibility,
            f_dest_weather_oat: this.dest_oat,
            f_dest_weather_wind_spd: this.dest_wind_spd,
            f_dest_weather_qnh: this.dest_qnh,
            cb: this.userdata.FullName,
            cd: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          };
          var res = await feathersClientUOA.service("flight").create(DataAdd);

          try {
            await this.$refs.CompAddPassengerOutbound2.SavePassengerEditToDB(
              res.f_id
            );
          } catch (error) {
            console.log(error);
          }
          try {
            await this.$refs.CompAddPassengerInbound2.SavePassengerEditToDB(
              res.f_id
            );
          } catch (error) {
            console.log(error);
          }
          this.CreateLegsRawData(res.f_id);
          this.CreateCargoRawData(res.f_id);
          this.dialogSave = false;
          this.dialogConfirm = true;
          //Edit delete
          this.GotoListFlight();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async CreateFlightWithoutBookingId() {
      try {
        var DataAdd = {
          ac_id: this.aircraft.ac_id,

          f_flight_no: this.flightno,
          tm_id: this.terminal.tm_id,
          v_id: this.videoCheckin.v_id,
          // c_id: this.company.c_id,
          //Edit
          o_id: this.customer.o_id,

          ap_depart_date: this.depart_date + " " + this.depart_time,
          ap_dest_date: this.dest_date + " " + this.dest_time,
          // f_type_of_flight: this.flighttype,
          //Edit
          ft_id: this.flighttype.ft_id,
          f_type_of_flight: this.flighttype.ft_name,

          fs_id: this.flightstatus.fs_id,

          // f_pilot_name: this.pilot,
          // f_copilot_name: this.copilot,

          ap_departairport_id: this.departureairport.ap_id,
          ap_destairport_id: this.destinationairport.ap_id,
          f_depart_weather_visibility: this.depart_visibility,

          f_depart_weather_oat: this.depart_oat,
          f_depart_weather_wind_spd: this.depart_wind_spd,
          f_depart_weather_qnh: this.depart_qnh,
          f_dest_weather_visibility: this.dest_visibility,
          f_dest_weather_oat: this.dest_oat,
          f_dest_weather_wind_spd: this.dest_wind_spd,
          f_dest_weather_qnh: this.dest_qnh,
          cb: this.userdata.FullName,
          cd: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        };
        var res = await feathersClientUOA.service("flight").create(DataAdd);

        try {
          await this.$refs.CompAddPassengerOutbound2.SavePassengerEditToDB(
            res.f_id
          );
        } catch (error) {
          console.log(error);
        }
        try {
          await this.$refs.CompAddPassengerInbound2.SavePassengerEditToDB(
            res.f_id
          );
        } catch (error) {
          console.log(error);
        }
        this.CreateLegsRawData(res.f_id);
        this.CreateCargoRawData(res.f_id);
        this.dialogSave = false;
        this.dialogConfirm = true;
        //Edit delete
        //this.GotoListFlight();

        await this.DataFlightFromDB();
        this.mode = "Edit";

        var url = "#/flightdetail/Edit/" + res.f_id;
        window.location.replace(url);
      } catch (error) {
        console.log(error);
      }
    },
    async CreateLegsRawData(DataFlight) {
      try {
        this.listAddLegs.forEach((element) => {
          var DataAdd = {
            f_id: DataFlight,
            ap_id: element.legs.ap_id,
            ap_name: element.legs.ap_name,
            ap_color: element.legs.ap_color,
            l_activity1: element.activity1 === false ? 0 : 1,
            l_activity2: element.activity2 === false ? 0 : 1,
            l_activity3: element.activity3 === false ? 0 : 1,
            l_activity4: element.activity4 === false ? 0 : 1,
            l_activity5: element.activity5 === false ? 0 : 1,
            l_weather_visibility: element.l_weather_visibility,
            l_weather_oat: element.l_weather_oat,
            l_weather_wind_spd: element.l_weather_wind_spd,
            l_weather_qnh: element.l_weather_qnh,
            l_etd: element.l_etd,
            l_eta: element.l_eta,

            retire: 0,
          };
          this.CreateLegs(DataAdd);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async CreateLegs(DataAdd) {
      try {
        var res = await feathersClientUOA.service("leg").create(DataAdd);
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          l_id: res.l_id,
          ap_id: DataAdd.ap_id,
          ap_name: DataAdd.ap_name,
          ap_color: DataAdd.ap_color,
          l_activity1: DataAdd.l_activity1,
          l_activity2: DataAdd.l_activity2,
          l_activity3: DataAdd.l_activity3,
          l_activity4: DataAdd.l_activity4,
          l_activity5: DataAdd.l_activity5,
          l_weather_visibility: DataAdd.l_weather_visibility,
          l_weather_oat: DataAdd.l_weather_oat,
          l_weather_wind_spd: DataAdd.l_weather_wind_spd,
          l_weather_qnh: DataAdd.l_weather_qnh,
          l_etd: DataAdd.l_etd,
          l_eta: DataAdd.l_eta
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "AddLegToFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddLegToFlight, Error : {error}", { error });
        console.log("AddLegToFlight, Error : {error}" + error);
      }
    },
    async CreateCargoRawData(DataFlight) {
      try {
        this.listAddCargo.forEach((element) => {
          let DataAdd = {
            f_id: DataFlight,
            cg_dan_no: element.cg_dan_no,
            cg_weight: element.cg_weight,
            cg_contact: element.cg_contact,
            cg_remark: element.cg_remark,
            ap_id: element.Destinations.ap_id,

            retire: 0,
          };
          this.CreateCargo(DataAdd);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async CreateCargo(DataAdd) {
      try {
        var res = await feathersClientUOA.service("cargo").create(DataAdd);
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight),
          cg_id: res.cg_id,
          cg_dan_no: DataAdd.cg_dan_no,
          cg_weight: DataAdd.cg_weight,
          cg_contact: DataAdd.cg_contact,
          cg_remark: DataAdd.cg_remark,
          ap_id: DataAdd.ap_id
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, cg_id={cg_id}', { action: "AddCargoToFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddCargoToFlight, Error : {error}", { error });
        console.log("AddCargoToFlight, Error : {error}" + error);
      }
    },
    async DeleteFlight() {
      var DataDelete = {
        mb: this.userdata.FullName,
        md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        retire: 1,
      };

      try {
        var res = await feathersClientUOA
          .service("flight")
          .patch(this.idflight, DataDelete);
      } catch (error) {
        console.log(error);
      }
      this.dialogDelete = false;
      this.$router.push("/flight");
    },
    async GotoListFlight() {
      this.$router.push("/flight");
    },
    async SaveCompleted() {
      this.dialogConfirm = false;
      this.CompareLeg();
      this.RenderUI();
    },
    async SendNotificationCompleted() {
      this.dialogSendNotificationComplete = false;
      await this.RenderUI();
    },
    async BodyOut(dataOut) {
      this.sumfromOutboundPassenger = dataOut;
      this.BodyWeightInAndOut();
    },
    async BodyIn(dataIn) {
      this.sumfromInboundPassenger = dataIn;
      this.BodyWeightInAndOut();
    },
    async BaggageOut(dataOut) {
      this.sumfromOutboundPassengerBaggage = dataOut;
      this.BaggageWeightInAndOut();
    },
    async BaggageIn(dataIn) {
      this.sumfromInboundPassengerBaggage = dataIn;
      this.BaggageWeightInAndOut();
    },
    async BodyWeightInAndOut() {
      this.sumbodyweight =
        parseFloat(this.sumfromInboundPassenger) +
        parseFloat(this.sumfromOutboundPassenger);
      this.SumDataTotal();
    },
    async BaggageWeightInAndOut() {
      this.sumbaggageweight =
        parseFloat(this.sumfromOutboundPassengerBaggage) +
        parseFloat(this.sumfromInboundPassengerBaggage);
      this.SumDataTotal();
    },
    async SumDataCargo() {
      this.sumcargo = 0;
      this.listAddCargo.forEach((element) => {
        this.sumcargo += parseFloat(element.cg_weight);
      });
      this.sumcargo = parseFloat(this.sumcargo);
    },
    async SumDataTotal() {
      this.sumTotal =
        parseFloat(this.sumcargo) +
        parseFloat(this.sumbodyweight) +
        parseFloat(this.sumbaggageweight);
    },
    Back() {
      this.$router.go(-1);
    },
    async SemiManifest() {
      await this.SelectListPassengerSemi_Manifest();

      var pdfMake = require("../../public/js/pdfmake");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("../../public/js/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        pageOrientation: "landscape",
        pageMargins: [20, 20, 20, 20],
        content: [
          // {
          //   image: './public/favicon.png',
          //   width: 150,
          //   height: 150,
          // },
          {
            style: "tableExample",
            table: {
              widths: [786],
              heights: 4,
              body: [
                [
                  {
                    border: [true, true, true, false],
                    text: "Passenger booking",
                    style: "header",
                  },
                ],
              ],
            },
          },
          {
            style: "tableExample",
            table: {
              widths: [120, 120, 120, 399],
              heights: 4,
              body: [
                [
                  {
                    border: [true, true, false, false],
                    text: "Flight Date :",
                    style: "texttitle",
                  },
                  {
                    border: [false, true, false, false],
                    text: format(
                      new Date(this.itemsDataFlight.ap_depart_date),
                      "dd-MMM-yy"
                    ),
                  },
                  {
                    border: [false, true, false, false],
                    text: "Aircratf :",
                    style: "texttitle",
                  },
                  {
                    border: [false, true, true, false],
                    text: this.aircarftName,
                  },
                ],
                [
                  {
                    border: [true, false, false, false],
                    text: "Flight No. :",
                    style: "texttitle",
                  },
                  {
                    border: [false, false, false, false],
                    text: this.itemsDataFlight.f_flight_no,
                  },
                  {
                    border: [false, false, false, false],
                    text: "ETD :",
                    style: "texttitle",
                  },
                  {
                    border: [false, false, true, false],
                    text: format(
                      addHours(
                        new Date(this.itemsDataFlight.ap_depart_date),
                        -7
                      ),
                      "HH:mm"
                    ),
                  },
                ],
                [
                  {
                    border: [true, false, false, true],
                    text: "Route :",
                    style: "texttitle",
                  },
                  {
                    border: [false, false, true, true],
                    // text: "VTSH" + this.dataLegs + "VTSH",
                    text:
                      this.itemsListAirport.filter(
                        (x) => x.ap_id == this.departureairport
                      )[0].ap_name +
                      this.dataLegs +
                      this.itemsListAirport.filter(
                        (x) => x.ap_id == this.departureairport
                      )[0].ap_name,
                    colSpan: 3,
                  },
                  // { border: [false, false, false, true], text: "" },
                  // { border: [false, false, true, true], text: "" },
                ],
              ],
            },
          },
          {
            columns: [
              {
                style: "tableOutBound",
                table: {
                  //widths: [75, 20, 135,55,60,60,30,30,65,78,59],
                  //widths: [35 8, 70, 24, 23, 23, 15, 15, 30, 30, 20],
                  widths: [45, 8, 60, 24, 23, 23, 15, 15, 30, 30, 20],
                  heights: 4,
                  body: [
                    [
                      {
                        text: "OUTBOUND",
                        colSpan: 8,
                        alignment: "center",
                        style: "textgrey",
                      },
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      {
                        text: "Expired date",
                        colSpan: 2,
                        alignment: "center",
                        style: "textgrey",
                      },
                      "",
                      { text: "Remark", rowSpan: 2, style: "textgrey" },
                    ],
                    [
                      { text: "PAX ID/Passport", style: "textgrey" },
                      { text: "No.", style: "textgrey" },
                      { text: "Passenger Name", style: "textgrey" },
                      { text: "Company", style: "textgrey" },
                      { text: "Pax Weight", style: "textgrey" },
                      { text: "Bag Weight", style: "textgrey" },
                      { text: "From", style: "textgrey" },
                      { text: "To", style: "textgrey" },
                      { text: "Safety Training", style: "textgrey" },
                      { text: "Medical CheckUp", style: "textgrey" },
                      { text: "", style: "textgrey" },
                    ],
                    [
                      {
                        text: this.listPassengersOut[0].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[0].No },
                      {
                        text: this.listPassengersOut[0].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[0].c_name },
                      { text: this.listPassengersOut[0].p_body_weight },
                      { text: this.listPassengersOut[0].p_baggage_weight },
                      { text: this.listPassengersOut[0].from },
                      { text: this.listPassengersOut[0].to },
                      { text: this.listPassengersOut[0].u_safety_training },
                      { text: this.listPassengersOut[0].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[1].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[1].No },
                      {
                        text: this.listPassengersOut[1].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[1].c_name },
                      { text: this.listPassengersOut[1].p_body_weight },
                      { text: this.listPassengersOut[1].p_baggage_weight },
                      { text: this.listPassengersOut[1].from },
                      { text: this.listPassengersOut[1].to },
                      { text: this.listPassengersOut[1].u_safety_training },
                      { text: this.listPassengersOut[1].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[2].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[2].No },
                      {
                        text: this.listPassengersOut[2].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[2].c_name },
                      { text: this.listPassengersOut[2].p_body_weight },
                      { text: this.listPassengersOut[2].p_baggage_weight },
                      { text: this.listPassengersOut[2].from },
                      { text: this.listPassengersOut[2].to },
                      { text: this.listPassengersOut[4].u_safety_training },
                      { text: this.listPassengersOut[2].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[3].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[3].No },
                      {
                        text: this.listPassengersOut[3].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[3].c_name },
                      { text: this.listPassengersOut[3].p_body_weight },
                      { text: this.listPassengersOut[3].p_baggage_weight },
                      { text: this.listPassengersOut[3].from },
                      { text: this.listPassengersOut[3].to },
                      { text: this.listPassengersOut[3].u_safety_training },
                      { text: this.listPassengersOut[3].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[4].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[4].No },
                      {
                        text: this.listPassengersOut[4].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[4].c_name },
                      { text: this.listPassengersOut[4].p_body_weight },
                      { text: this.listPassengersOut[4].p_baggage_weight },
                      { text: this.listPassengersOut[4].from },
                      { text: this.listPassengersOut[4].to },
                      { text: this.listPassengersOut[4].u_safety_training },
                      { text: this.listPassengersOut[4].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[5].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[5].No },
                      {
                        text: this.listPassengersOut[5].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[5].c_name },
                      { text: this.listPassengersOut[5].p_body_weight },
                      { text: this.listPassengersOut[5].p_baggage_weight },
                      { text: this.listPassengersOut[5].from },
                      { text: this.listPassengersOut[5].to },
                      { text: this.listPassengersOut[5].u_safety_training },
                      { text: this.listPassengersOut[5].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[6].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[6].No },
                      {
                        text: this.listPassengersOut[6].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[6].c_name },
                      { text: this.listPassengersOut[6].p_body_weight },
                      { text: this.listPassengersOut[6].p_baggage_weight },
                      { text: this.listPassengersOut[6].from },
                      { text: this.listPassengersOut[6].to },
                      { text: this.listPassengersOut[6].u_safety_training },
                      { text: this.listPassengersOut[6].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[7].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[7].No },
                      {
                        text: this.listPassengersOut[7].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[7].c_name },
                      { text: this.listPassengersOut[7].p_body_weight },
                      { text: this.listPassengersOut[7].p_baggage_weight },
                      { text: this.listPassengersOut[7].from },
                      { text: this.listPassengersOut[7].to },
                      { text: this.listPassengersOut[7].u_safety_training },
                      { text: this.listPassengersOut[7].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[8].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[8].No },
                      {
                        text: this.listPassengersOut[8].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[8].c_name },
                      { text: this.listPassengersOut[8].p_body_weight },
                      { text: this.listPassengersOut[8].p_baggage_weight },
                      { text: this.listPassengersOut[8].from },
                      { text: this.listPassengersOut[8].to },
                      { text: this.listPassengersOut[8].u_safety_training },
                      { text: this.listPassengersOut[8].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[9].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[9].No },
                      {
                        text: this.listPassengersOut[9].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[9].c_name },
                      { text: this.listPassengersOut[9].p_body_weight },
                      { text: this.listPassengersOut[9].p_baggage_weight },
                      { text: this.listPassengersOut[9].from },
                      { text: this.listPassengersOut[9].to },
                      { text: this.listPassengersOut[9].u_safety_training },
                      { text: this.listPassengersOut[9].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[10].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[10].No },
                      {
                        text: this.listPassengersOut[10].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[10].c_name },
                      { text: this.listPassengersOut[10].p_body_weight },
                      { text: this.listPassengersOut[10].p_baggage_weight },
                      { text: this.listPassengersOut[10].from },
                      { text: this.listPassengersOut[10].to },
                      { text: this.listPassengersOut[10].u_safety_training },
                      { text: this.listPassengersOut[10].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersOut[11].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersOut[11].No },
                      {
                        text: this.listPassengersOut[11].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersOut[11].c_name },
                      { text: this.listPassengersOut[11].p_body_weight },
                      { text: this.listPassengersOut[11].p_baggage_weight },
                      { text: this.listPassengersOut[11].from },
                      { text: this.listPassengersOut[11].to },
                      { text: this.listPassengersOut[11].u_safety_training },
                      { text: this.listPassengersOut[11].u_medical_checkup },
                      "",
                    ],

                    [
                      {
                        text: "",
                        colSpan: 4,
                        border: [false, false, false, false],
                      },
                      "",
                      "",
                      "",
                      { text: this.totalBody_KG_Out },
                      { text: this.totalBaggage_KG_Out },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      {
                        text: "",
                        colSpan: 2,
                        border: [false, false, false, false],
                      },
                      "",
                    ],
                    [
                      {
                        text: "",
                        colSpan: 4,
                        border: [false, false, false, false],
                      },
                      "",
                      "",
                      "",
                      { text: this.sumtotal_KG_Out },
                      { text: "KG" },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      {
                        text: "",
                        colSpan: 2,
                        border: [false, false, false, false],
                      },
                      "",
                    ],
                  ],
                },
                margin: [0, 5, 10, 0],
              },
              {
                style: "tableInBound",
                table: {
                  //widths: [75, 20, 135,55,60,60,30,30,65,78,59],
                  //widths: [35, 8, 70, 24, 23, 23, 15, 15, 30, 30, 20],
                  widths: [45, 8, 60, 24, 23, 23, 15, 15, 30, 30, 20],
                  heights: 4,
                  body: [
                    [
                      {
                        text: "INBOUND",
                        colSpan: 8,
                        alignment: "center",
                        style: "textgrey",
                      },
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      {
                        text: "Expired date",
                        colSpan: 2,
                        alignment: "center",
                        style: "textgrey",
                      },
                      "",
                      { text: "Remark", rowSpan: 2, style: "textgrey" },
                    ],
                    [
                      { text: "PAX ID/Passport", style: "textgrey" },
                      { text: "No.", style: "textgrey" },
                      { text: "Passenger Name", style: "textgrey" },
                      { text: "Company", style: "textgrey" },
                      { text: "Pax Weight", style: "textgrey" },
                      { text: "Bag Weight", style: "textgrey" },
                      { text: "From", style: "textgrey" },
                      { text: "To", style: "textgrey" },
                      { text: "Safety Training", style: "textgrey" },
                      { text: "Medical CheckUp", style: "textgrey" },
                      { text: "", style: "textgrey" },
                    ],
                    [
                      {
                        text: this.listPassengersIn[0].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[0].No },
                      {
                        text: this.listPassengersIn[0].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[0].c_name },
                      { text: this.listPassengersIn[0].p_body_weight },
                      { text: this.listPassengersIn[0].p_baggage_weight },
                      { text: this.listPassengersIn[0].from },
                      { text: this.listPassengersIn[0].to },
                      { text: this.listPassengersIn[0].u_safety_training },
                      { text: this.listPassengersIn[0].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[1].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[1].No },
                      {
                        text: this.listPassengersIn[1].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[1].c_name },
                      { text: this.listPassengersIn[1].p_body_weight },
                      { text: this.listPassengersIn[1].p_baggage_weight },
                      { text: this.listPassengersIn[1].from },
                      { text: this.listPassengersIn[1].to },
                      { text: this.listPassengersIn[1].u_safety_training },
                      { text: this.listPassengersIn[1].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[2].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[2].No },
                      {
                        text: this.listPassengersIn[2].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[2].c_name },
                      { text: this.listPassengersIn[2].p_body_weight },
                      { text: this.listPassengersIn[2].p_baggage_weight },
                      { text: this.listPassengersIn[2].from },
                      { text: this.listPassengersIn[2].to },
                      { text: this.listPassengersIn[4].u_safety_training },
                      { text: this.listPassengersIn[2].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[3].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[3].No },
                      {
                        text: this.listPassengersIn[3].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[3].c_name },
                      { text: this.listPassengersIn[3].p_body_weight },
                      { text: this.listPassengersIn[3].p_baggage_weight },
                      { text: this.listPassengersIn[3].from },
                      { text: this.listPassengersIn[3].to },
                      { text: this.listPassengersIn[3].u_safety_training },
                      { text: this.listPassengersIn[3].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[4].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[4].No },
                      {
                        text: this.listPassengersIn[4].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[4].c_name },
                      { text: this.listPassengersIn[4].p_body_weight },
                      { text: this.listPassengersIn[4].p_baggage_weight },
                      { text: this.listPassengersIn[4].from },
                      { text: this.listPassengersIn[4].to },
                      { text: this.listPassengersIn[4].u_safety_training },
                      { text: this.listPassengersIn[4].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[5].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[5].No },
                      {
                        text: this.listPassengersIn[5].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[5].c_name },
                      { text: this.listPassengersIn[5].p_body_weight },
                      { text: this.listPassengersIn[5].p_baggage_weight },
                      { text: this.listPassengersIn[5].from },
                      { text: this.listPassengersIn[5].to },
                      { text: this.listPassengersIn[5].u_safety_training },
                      { text: this.listPassengersIn[5].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[6].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[6].No },
                      {
                        text: this.listPassengersIn[6].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[6].c_name },
                      { text: this.listPassengersIn[6].p_body_weight },
                      { text: this.listPassengersIn[6].p_baggage_weight },
                      { text: this.listPassengersIn[6].from },
                      { text: this.listPassengersIn[6].to },
                      { text: this.listPassengersIn[6].u_safety_training },
                      { text: this.listPassengersIn[6].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[7].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[7].No },
                      {
                        text: this.listPassengersIn[7].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[7].c_name },
                      { text: this.listPassengersIn[7].p_body_weight },
                      { text: this.listPassengersIn[7].p_baggage_weight },
                      { text: this.listPassengersIn[7].from },
                      { text: this.listPassengersIn[7].to },
                      { text: this.listPassengersIn[7].u_safety_training },
                      { text: this.listPassengersIn[7].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[8].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[8].No },
                      {
                        text: this.listPassengersIn[8].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[8].c_name },
                      { text: this.listPassengersIn[8].p_body_weight },
                      { text: this.listPassengersIn[8].p_baggage_weight },
                      { text: this.listPassengersIn[8].from },
                      { text: this.listPassengersIn[8].to },
                      { text: this.listPassengersIn[8].u_safety_training },
                      { text: this.listPassengersIn[8].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[9].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[9].No },
                      {
                        text: this.listPassengersIn[9].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[9].c_name },
                      { text: this.listPassengersIn[9].p_body_weight },
                      { text: this.listPassengersIn[9].p_baggage_weight },
                      { text: this.listPassengersIn[9].from },
                      { text: this.listPassengersIn[9].to },
                      { text: this.listPassengersIn[9].u_safety_training },
                      { text: this.listPassengersIn[9].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[10].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[10].No },
                      {
                        text: this.listPassengersIn[10].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[10].c_name },
                      { text: this.listPassengersIn[10].p_body_weight },
                      { text: this.listPassengersIn[10].p_baggage_weight },
                      { text: this.listPassengersIn[10].from },
                      { text: this.listPassengersIn[10].to },
                      { text: this.listPassengersIn[10].u_safety_training },
                      { text: this.listPassengersIn[10].u_medical_checkup },
                      "",
                    ],
                    [
                      {
                        text: this.listPassengersIn[11].u_idcard_no,
                        color: "#FF0000",
                      },
                      { text: this.listPassengersIn[11].No },
                      {
                        text: this.listPassengersIn[11].u_name,
                        alignment: "left",
                      },
                      { text: this.listPassengersIn[11].c_name },
                      { text: this.listPassengersIn[11].p_body_weight },
                      { text: this.listPassengersIn[11].p_baggage_weight },
                      { text: this.listPassengersIn[11].from },
                      { text: this.listPassengersIn[11].to },
                      { text: this.listPassengersIn[11].u_safety_training },
                      { text: this.listPassengersIn[11].u_medical_checkup },
                      "",
                    ],

                    [
                      {
                        text: "",
                        colSpan: 4,
                        border: [false, false, false, false],
                      },
                      "",
                      "",
                      "",
                      { text: this.totalBody_KG_In },
                      { text: this.totalBaggage_KG_In },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      {
                        text: "",
                        colSpan: 2,
                        border: [false, false, false, false],
                      },
                      "",
                    ],
                    [
                      {
                        text: "",
                        colSpan: 4,
                        border: [false, false, false, false],
                      },
                      "",
                      "",
                      "",
                      { text: this.sumtotal_KG_In },
                      { text: "KG" },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      { text: "", border: [false, false, false, false] },
                      {
                        text: "",
                        colSpan: 2,
                        border: [false, false, false, false],
                      },
                      "",
                    ],
                  ],
                },
              },
            ],
          },
          {
            columns: [
              {
                style: "tableInterfield",
                table: {
                  // widths: [35, 8, 70, 24, 23, 23, 15, 15, 30, 30, 20],
                  widths: [45, 8, 60, 24, 23, 23, 15, 15, 30, 30, 20],
                  heights: 4,
                  body: this.listPassengersInterField,
                },
              },
              {
                // 	table: {
                //   body: [
                //    ['', '', ''],
                //    ['', '', ''],
                //    ['', '', ''],
                //    ['', '', ''],
                //   ]
                // },
                // layout: 'noBorders'
              },
            ],
          },

          {
            text: "OPS-FM-93 Issue 01 Revision 01",
            fontSize: 6,
            style: "right",
          },
        ],
        styles: {
          header: {
            fontSize: 9,
            bold: true,
            fillColor: "#CBCACA",
            decoration: "underline",
            alignment: "center",
            margin: [0, 0, 0, 0],
          },
          right: {
            alignment: "right",
            margin: [0, 0, 0, 0],
          },
          tableExample: {
            fontSize: 5.5,
            margin: [0, 0, 0, 0],
          },
          tableOutBound: {
            fontSize: 5.5,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },

          tableInBound: {
            fontSize: 5.5,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          tableInterfield: {
            fontSize: 5.5,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          texttitle: {
            bold: true,
            margin: [0, 0, 0, 0],
          },
          textgrey: {
            bold: true,
            fillColor: "#CBCACA",
          },
        },
        defaultStyle: {
          // alignment: 'justify'
        },
      };

      pdfMake.createPdf(docDefinition).download("Semi_Manifest.pdf");
    },
    async Getalcoholcheckbyperson() { },

    async CalPilotRank(data) {
      this.pilotrank = data.pilot_rank;
      this.sumrank = this.pilotrank + this.copilotrank;

      if (data.pl_weight_kg != null) {
        this.pilotWeigt = data.pl_weight_kg;
        this.pilotTotalWeightKg = parseInt(this.pilotWeigt) + parseInt(this.copilotWeight);
      }
    },
    async CalCoPilotRank(data) {
      this.copilotrank = data.pilot_rank;
      this.sumrank = this.pilotrank + this.copilotrank;

      if (data.pl_weight_kg != null) {
        this.copilotWeight = data.pl_weight_kg;
        this.pilotTotalWeightKg = parseInt(this.pilotWeigt) + parseInt(this.copilotWeight);
      }
    },

    async AcceptAircraft() {
      try {
        this.buttonAcceptAircraft = true;
        var dataEdit = {
          f_accept_aircraft: true,
          f_accept_aircraft_by: this.userdata.FullName,
          f_accept_aircraft_date: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        };
        var res = await feathersClientUOA.service("flight").patch(this.idflight, dataEdit);
        //SEQ
        let properties = {
          f_id: this.idflight,
          f_accept_aircraft: dataEdit.f_accept_aircraft,
          f_accept_aircraft_by: dataEdit.f_accept_aircraft_by,
          f_accept_aircraft_date: dataEdit.f_accept_aircraft_date
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, f_accept_aircraft_by={f_accept_aircraft_by}, f_accept_aircraft_date={f_accept_aircraft_date}', { action: "AcceptAircraftAndNotifyForFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
        //Notification
        this.$Notification.FlightAcceptAircraft(this.idflight);
        this.buttonAcceptAircraft = false;
        this.dialogSendNotificationComplete = true;
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AcceptAircraftAndNotifyForFlight, Error : {error}", { error });
        console.log("AcceptAircraftAndNotifyForFlight, Error : {error}" + error);
      }
    },
    async AcceptPilot() {
      try {
        this.buttonAcceptPilot = true;
        var dataEdit = {
          f_accept_pilot: true,
          f_accept_pilot_by: this.userdata.FullName,
          f_accept_pilot_date: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        };
        var res = await feathersClientUOA.service("flight").patch(this.idflight, dataEdit);
        //SEQ
        let properties = {
          f_id: this.idflight,
          f_accept_pilot: dataEdit.f_accept_pilot,
          f_accept_pilot_by: dataEdit.f_accept_pilot_by,
          f_accept_pilot_date: dataEdit.f_accept_pilot_date
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, f_accept_pilot_by={f_accept_pilot_by}, f_accept_pilot_date={f_accept_pilot_date}', { action: "AcceptPilotAndNotifyForFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
        //Notification
        this.$Notification.FlightAcceptPilot(this.idflight);
        this.buttonAcceptPilot = false;
        this.dialogSendNotificationComplete = true;
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AcceptPilotAndNotifyForFlight, Error : {error}", { error });
        console.log("AcceptPilotAndNotifyForFlight, Error : {error}" + error);
      }
    },
    async CompareLeg() {
      try {
        var q = {};
        q.PassengerType = 1;
        let Leg = await feathersClientUOA.service("flightcompareservice").patch("ComepareLeg", { Id: this.idflight });
        this.dataCompareLeg = Leg[0].dataleg;
        this.totalLeg = this.dataCompareLeg.length;
        this.dataCompareDepartureDate = Leg[0].DateTime;
        this.totalDepartureDate = this.dataCompareDepartureDate.length;

        let departDate =
          this.dataCompareDepartureDate[0].Datetime.substring(0, 10) +
          " " +
          this.dataCompareDepartureDate[0].Datetime.substring(11, 16);
        this.dataCompareDepartureDate[0].Datetime = departDate;
      } catch (err) {
        console.log("CompareLeg ไม่สามารถต่อ server ได้ err " + err);
      }
    },

    async syncDataLeg() {
      try {
        await feathersClientUOA.service("flightcompareservice").patch("SyncLeg", { Id: this.idflight });
        this.DataLegsFromDB();
        this.CompareLeg();

        //SEQ
        let properties = {
          f_id: parseInt(this.idflight)
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "SyncLeg", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("SyncLeg, Error : {error}", { error });
        console.log("SyncLeg, Error : {error}" + error);
      }
    },

    async syncDeparture() {
      try {
        await feathersClientUOA.service("flightcompareservice").patch("SyncDepartureDate", { Id: this.idflight });
        this.CompareLeg();
        this.RenderUI();
        //SEQ
        let properties = {
          f_id: parseInt(this.idflight)
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "SyncDepartureDate", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("SyncDepartureDate, Error : {error}", { error });
        console.log("SyncDepartureDate, Error : {error}" + error);
      }
    },
    async deleteflight1() {
      if (this.confirmdeleteflight == "YES") {
        try {
          var res = await feathersClientUOA.service("flight").remove(this.idflight);
          //SEQ
          let properties = {
            f_id: this.idflight,
          };
          this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "DeleteFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
          this.dialogDelete = false;
          this.$router.push("/flight");
        } catch (error) {
          //SEQ LOG
          this.$log.Error("DeleteFlight, Error : {error}", { error });
          console.log("DeleteFlight, Error : {error}" + error);
        }
      }
    },
    ReviseRenderFlight() {
      this.RenderUI();
    },

    async SaveEditToDB_Role_Super_Flight_Off() {
      await this.SaveEditToDB();
      await this.SaveEditToDB_Role_Pi();
      await this.SaveEditToDB_Role_En();
      this.dialogSaveEP = false;
      this.dialogConfirm = true;
    },

    CancelSaveAvailablePayload() {
      this.available_payload = this.itemsDataFlight.f_available_payload;
      this.dialogInputAvailablePayload = false;
    },

    async SaveAvailablePayload() {
      try {
        let dataUpdate = {
          f_available_payload: this.available_payload,
        };
        var res = await feathersClientUOA.service("flight").patch(this.idflight, dataUpdate);
        //Notification
        this.$Notification.FlightOffUpdateAvailablePayload(this.idflight);
        this.itemsDataFlight.f_available_payload = this.available_payload;
        this.dialogInputAvailablePayload = false;
        //SEQ LOG
        let properties = {
          f_id: parseInt(this.idflight),
          f_available_payload: parseInt(dataUpdate.f_available_payload)
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, f_available_payload={f_available_payload}', { action: "AddAvailableLuggageForCheckin", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddAvailableLuggageForCheckin, Error : {error}", { error });
        console.log("AddAvailableLuggageForCheckin, Error : {error}" + error);
      }
    },



    CancelSaveAvailablePayloadLbs() {
      this.available_payload_lbs = this.itemsDataFlight.f_available_payload_lbs;
      this.dialogInputAvailablePayloadLbs = false;
    },

    async SaveAvailablePayloadLbs() {
      try {
        let dataUpdate = {
          f_available_payload_lbs: this.available_payload_lbs,
        };
        var res = await feathersClientUOA.service("flight").patch(this.idflight, dataUpdate);
        //Notification
        this.$Notification.FlightOffUpdateAvailablePayloadLbs(this.idflight);
        this.itemsDataFlight.f_available_payload_lbs = this.available_payload_lbs;
        this.dialogInputAvailablePayloadLbs = false;
        //SEQ LOG
        let properties = {
          f_id: parseInt(this.idflight),
          f_available_payload: parseInt(dataUpdate.f_available_payload_lbs)
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, f_available_payload_lbs={f_available_payload_lbs}', { action: "AddAvailableLuggageForCheckin", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddAvailableLuggageForCheckin, Error : {error}", { error });
        console.log("AddAvailableLuggageForCheckin, Error : {error}" + error);
      }
    },
  },
};
</script>
<style scoped>
.mdi:before,
.mdi-set {
  color: #33cc33;
}
</style>
<style>
.v-messages__message {
  color: #b0afaf !important;
}
</style>